import jiraLogo from "../../assets/icons/acsselfonboarding/jiraservicedesk.png";
import slackLogo from "../../assets/icons/acsselfonboarding/slack.png";
import remedyLogo from "../../assets/icons/acsselfonboarding/bmcremedy.png";
import moment from "moment";

/** import constants */
import {
  LOAD_SHIFT_REQUEST,
  LOAD_SHIFT_SUCCESS,
  LOAD_SHIFT_FAILED,
  DELETE_SHIFT_REQUEST,
  DELETE_SHIFT_SUCCESS,
  DELETE_SHIFT_FAILED,
  CREATE_SHIFT_REQUEST,
  CREATE_SHIFT_SUCCESS,
  CREATE_SHIFT_FAILED,
  UPDATE_SHIFT_SUCCESS,
  UPDATE_SHIFT_REQUEST,
  UPDATE_SHIFT_FAILED,
  UPDATE_SELECTED_ASSIGNMENT_GROUP,
  UPDATE_SHIFT_STARTTIME,
  UPDATE_SHIFT_ENDTIME,
  UPDATE_CONTACT,
  UPDATE_CHECK_FIELD_VALUE,
  UPDATE_INTERVAL,
  OPEN_CURRENT_CONFIG_WINDOW,
  CLOSE_CURRENT_CONFIG_WINDOW,
  UPDATE_OPERATION_INITIATED,
  UPDATE_WEBHOOK_URL,
  UPDATE_CONTACT_NUMBERS,
  UPDATE_SHIFT_OBJECT_WITH_DEFAULT_SCHEMA,
  UPDATE_PRIORITY,
  UPDATE_FORM_VALIDATION,
  UPDATE_FORM_SUBMIT_STATUS,
  SET_JIRA_PROJECTS,
  SET_IDESK_ASSIGNMENTGROUPS,
  SET_SMS_FOR,
  SET_SLACK_FOR,
  SET_TEAMS_FOR,
  SET_WATSAPP_FOR,
  OPEN_REPORT_WINDOW,
  CLOSE_REPORT_WINDOW
} from "./types";

const initialState = {
  jiraProjects: [],
  ideskAssignmentGroups: [],
  isformsubmitted: false,
  loading: false,
  isform1valid: false,
  isform2valid: false,
  isform3valid: false,
  isform4valid: false,
  isform5valid: false,
  isform6valid: false,
  platFormItems: [
    {
      name: "idesk",
      fullName: "BMC iDesk (Service Desk)",
      src: remedyLogo,
      disabled: false,
    },
    { name: "jira", fullName: "JIRA", src: jiraLogo, disabled: false },
    {
      name: "slack",
      fullName: "Slack Communicator",
      src: slackLogo,
      disabled: false,
    },
  ],
  selectedPlatformItem: "",
  assignmentGroup: "",
  jiraProjectPriority: [],
  jiraIssueType: "",
  shifts: [],
  availableModes: ["add", "edit"],
  mode: "add",
  id: "",
  startTime: moment().hours(0).minutes(0).seconds(0),
  endTime: moment().hours(23).minutes(59).seconds(0),
  isenabledview: false,
  isenabledreportview: false,
  issecondaryenabled: false,
  isescalationenabled: false,
  isapnenabled: false,
  isrpnenabled: false,
  isasnenabled: false,
  isrsnenabled: false,
  isopsbridgeenabled: false,
  primarycontactnumber: "",
  alternateprimarycontactnumber: "",
  secondarycontactnumber: "",
  alternatesecondarycontactnumber: "",
  escalationcontactnumber: "",
  intervalp2s: 0,
  intervals2e: 0,
  intervalp2ap: 0,
  intervals2as: 0,
  intervalp2rp: 0,
  intervals2rs: 0,
  issmsenabled: false,
  sms_numbers: [],
  smsfor: [],
  isteamsenabled: false,
  teamsWebhookUrl: "",
  teamsfor: [],
  isslackenabled: false,
  slackWebhookUrl: "",
  slackfor: [],
  iswatsappenabled: false,
  whatsapp_numbers: [],
  whatsappfor: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SMS_FOR:
      return {
        ...state,
        smsfor: action.payload,
      };
    case SET_TEAMS_FOR:
      return {
        ...state,
        teamsfor: action.payload,
      };
    case SET_SLACK_FOR:
      return {
        ...state,
        slackfor: action.payload,
      };
    case SET_WATSAPP_FOR:
      return {
        ...state,
        whatsappfor: action.payload,
      };
    case SET_JIRA_PROJECTS:
      return {
        ...state,
        jiraProjects: action.payload,
      };
    case SET_IDESK_ASSIGNMENTGROUPS:
      return {
        ...state,
        ideskAssignmentGroups: action.payload
      }
    case UPDATE_FORM_SUBMIT_STATUS:
      return {
        ...state,
        isformsubmitted: action.payload,
      };
    case UPDATE_SHIFT_OBJECT_WITH_DEFAULT_SCHEMA:
      return {
        ...state,
        ...action.payload,
      };
    case LOAD_SHIFT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case LOAD_SHIFT_SUCCESS:
      return {
        ...state,
        loading: false,
        shifts: action.payload,
      };
    case LOAD_SHIFT_FAILED:
      return {
        ...state,
        loading: false,
      };
    case DELETE_SHIFT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case DELETE_SHIFT_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case DELETE_SHIFT_FAILED:
      return {
        ...state,
        loading: false,
      };

    case CREATE_SHIFT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case CREATE_SHIFT_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case CREATE_SHIFT_FAILED:
      return {
        ...state,
        loading: false,
      };

    case UPDATE_SHIFT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_SHIFT_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case UPDATE_SHIFT_FAILED:
      return {
        ...state,
        loading: false,
      };

    case "UPDATE_PLATFORM_SELECTED":
      return {
        ...state,
        selectedPlatformItem: action.name,
      };
    case UPDATE_SELECTED_ASSIGNMENT_GROUP:
      return {
        ...state,
        assignmentGroup: action.payload,
      };
    case UPDATE_SHIFT_STARTTIME:
      return {
        ...state,
        startTime: action.payload,
      };
    case UPDATE_SHIFT_ENDTIME:
      return {
        ...state,
        endTime: action.payload,
      };
    case UPDATE_CONTACT:
      return {
        ...state,
        [action.payload.name]: action.payload.value,
      };
    case UPDATE_FORM_VALIDATION:
      return {
        ...state,
        [action.payload.name]: action.payload.value,
      };
    case UPDATE_PRIORITY:
      return {
        ...state,
        [action.payload.name]: action.payload.value,
      };
    case UPDATE_CHECK_FIELD_VALUE:
      return {
        ...state,
        [action.payload.name]: action.payload.value,
      };
    case UPDATE_INTERVAL:
      return {
        ...state,
        [action.payload.name]: action.payload.value,
      };
    case UPDATE_WEBHOOK_URL:
      return {
        ...state,
        [action.payload.name]: action.payload.url,
      };
    case UPDATE_CONTACT_NUMBERS:
      // console.log(action.payload);
      return {
        ...state,
        [action.payload.name]: action.payload.numbers,
      };
    case OPEN_CURRENT_CONFIG_WINDOW:
      return {
        ...state,
        isenabledview: true,
      };
    case CLOSE_CURRENT_CONFIG_WINDOW:
      return {
        ...state,
        isenabledview: false,
      };
    case OPEN_REPORT_WINDOW:
      return {
        ...state,
        isenabledreportview: true
      }
    case CLOSE_REPORT_WINDOW:
      return {
        ...state,
        isenabledreportview: false
      }
    case UPDATE_OPERATION_INITIATED:
      return {
        ...state,
        ...action.payload,
        mode: "edit",
      };
    default:
      return { ...state };
  }
};

export default reducer;
