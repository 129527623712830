import {
  SV_SET_FETCHING_FLAG,
  SV_SET_OS_TYPE,
  SV_SET_STORAGE_TYPE,
  SV_SET_SELECTED_SERVICE,
  SV_GET_SERVICES_LIST,
  SV_GET_SYSTEMS_INFO,
  SV_GET_SERVERS_LIST,
  SV_GET_INSIGHTS,
  SV_GET_TREND_VIZ,
  SV_SELECTED_PLATFORM
} from "./types";

const initialState = {
  selectedOsType: "Linux",
  selectedStorageType: "Blocks",
  servicesList: [],
  isFetchingServicesList: false,
  selectedService: "",
  systemsInfo: [],
  isFetchingSystemsInfo: false,
  serversList: [],
  isFetchingServersList: false,
  insights: {},
  isFetchingInsights: false,
  trendData: {},
  isFetchingTrendData: false,
  selectedPlatform: 0
};

const reducer = (state = initialState, action) => {
  const type = action.type;

  switch (type) {
    case SV_SET_OS_TYPE: {
      return {
        ...state,
        selectedOsType: action.payload,
      };
    }
    case SV_SELECTED_PLATFORM: {
      return {
        ...state,
        selectedPlatform: action.payload,
      };
    }
    case SV_SET_STORAGE_TYPE: {
      return {
        ...state,
        selectedStorageType: action.payload,
      };
    }
    case SV_SET_SELECTED_SERVICE: {
      return {
        ...state,
        selectedService: action.payload,
      };
    }
    case SV_GET_SERVICES_LIST: {
      return {
        ...state,
        servicesList: action.payload,
      };
    }
    case SV_GET_SYSTEMS_INFO: {
      return {
        ...state,
        systemsInfo: action.payload,
      };
    }
    case SV_GET_SERVERS_LIST: {
      return {
        ...state,
        serversList: action.payload,
      };
    }
    case SV_GET_INSIGHTS: {
      return {
        ...state,
        insights: action.payload,
      };
    }
    case SV_GET_TREND_VIZ: {
      return {
        ...state,
        trendData: action.payload,
      };
    }
    case SV_SET_FETCHING_FLAG: {
      let apiType = action.payload.apiType;
      let value = action.payload.value;
      switch (apiType) {
        case "getServicesList": {
          return {
            ...state,
            isFetchingServicesList: value,
          };
        }
        case "getSystemsInfo": {
          return {
            ...state,
            isFetchingSystemsInfo: value,
          };
        }
        case "getServersList": {
          return {
            ...state,
            isFetchingServersList: value,
          };
        }
        case "getInsights": {
          return {
            ...state,
            isFetchingInsights: value,
          };
        }
        case "getTrendViz": {
          return {
            ...state,
            isFetchingTrendData: value,
          };
        }
        default:
          return { ...state };
      }
    }
    default:
      return { ...state };
  }
};

export default reducer;
