import {
  AUTHORIZATION_FAILED,
  AUTHORIZATION_SUCCESS,
  AUTHORIZATION_REQUEST,
  SAVE_CDS_ROLE,
  SET_LOADING,
  SET_CDS_USER_ID,
  SAVE_SPOOF_CDS_ROLE,
  TOGGLE_CDS_SPOOFING,
  LOGOUT,
  GET_AUTH0_TOKEN,
  SET_USER_EMAIL,
  SET_USER_GROUPS,
  GET_APIM_TOKEN
} from "./types";

const unionReportGroup = "TEST_OCC_UNION_REPORT"; // iLert OCC - Union Group
const pcRole = "P&C Business Partner"; //P&C Digital Technology
const pcRole2 = "Compensation and Benefits Specialist";
const occAdminGroup = "OCC_CONQUEROR";
const occGEPrivilege = "General.Employee";
const pcUsers = ["malgorzata.gugala@ingka.ikea.com", "sabarinathan.raghupathi@ingka.com"];

const initialState = {
  loading: false,
  roles: [],
  error: false,
  errorMessage: "",
  occPrivilege: "General.Manager", //Inside.External, General.Manager
  cdsRoles: [],
  isOCCManager: false,
  isPCMember: false,
  isUnionMember: false,
  isOCCAdmin: false,
  cdsUserId: "",
  cdsData: {},

  spoofCdsData: {},
  spoofCdsRoles: [],
  spoofIsOCCManager: false,
  spoofIsPCMember: false,
  spoofIsUnionMember: false,
  spoofIsOCCAdmin: false,
  spoofCdsUserId: "",
  isSpoofingEnabled: true,

  spoofIsGeneralEmployee: false,
  isGeneralEmployee: false,

  auth0token: "",
  userEmail: "",
  userGroup: [],
  APIMtoken: ""
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case AUTHORIZATION_REQUEST:
      return {
        ...state,
        loading: true,
        error: false,
        errorMessage: "",
      };
    case AUTHORIZATION_SUCCESS:
      return {
        ...state,
        roles: action.payload,
        loading: false,
        error: false,
        errorMessage: "",
      };
    case AUTHORIZATION_FAILED:
      return {
        ...state,
        roles: [],
        loading: false,
        error: true,
        errorMessage: action.payload,
      };
    case SAVE_CDS_ROLE: {
      sessionStorage.setItem("cdsUserId", action.payload.cdsData.userId);
      sessionStorage.setItem(
        "isOCCManager",
        action.payload.cdsData.roles.includes(state.occPrivilege)
          ? "true"
          : "false"
      );
      // sessionStorage.setItem('isPCMember', action.payload.cdsData.department === pcRole ? 'true' : 'false');
      sessionStorage.setItem(
        "isPCMember",
        action.payload.cdsData.jobTitle === pcRole ||
          action.payload.cdsData.jobTitle === pcRole2 || pcUsers.includes(state.userEmail) ? true : false
      );
      sessionStorage.setItem(
        "isUnionMember",
        action.payload.user["https://accounts.ikea.com/groups"].includes(
          unionReportGroup
        )
          ? "true"
          : "false"
      );
      sessionStorage.setItem(
        "isOCCAdmin",
        action.payload.user["https://accounts.ikea.com/groups"].includes(
          occAdminGroup
        )
          ? "true"
          : "false"
      );

      let cdsUserId = action.payload.cdsData.userId;
      let isOCCManager = action.payload.cdsData.roles.includes(
        state.occPrivilege
      );
      // let isPCMember = action.payload.cdsData.department === pcRole ? true : false;
      let isPCMember =
        action.payload.cdsData.jobTitle === pcRole ||
        action.payload.cdsData.jobTitle === pcRole2 || pcUsers.includes(state.userEmail) ? true : false;
      let isUnionMember =
        action.payload.user["https://accounts.ikea.com/groups"].includes(
          unionReportGroup
        );
      let isOCCAdmin =
        action.payload.user["https://accounts.ikea.com/groups"].includes(
          occAdminGroup
        );
      let isGeneralEmployee = action.payload.cdsData.roles.includes(occGEPrivilege);
      let cdsData = action.payload.cdsData;
      return {
        ...state,
        cdsUserId,
        cdsRoles: action.payload.cdsData.roles,
        isOCCManager,
        isPCMember,
        isUnionMember,
        isOCCAdmin,
        cdsData,
        isGeneralEmployee
      };
    }
    case SAVE_SPOOF_CDS_ROLE: {
      sessionStorage.setItem("spoofCdsUserId", action.payload.cdsData.userId);
      sessionStorage.setItem(
        "spoofIsOCCManager",
        action.payload.cdsData.roles.includes(state.occPrivilege)
          ? "true"
          : "false"
      );
      // sessionStorage.setItem('spoofIsPCMember', action.payload.cdsData.department === pcRole ? 'true' : 'false');
      sessionStorage.setItem(
        "spoofIsPCMember",
        action.payload.cdsData.jobTitle === pcRole ||
          action.payload.cdsData.jobTitle === pcRole2
          ? "true"
          : "false"
      );
      // sessionStorage.setItem('spoofIsUnionMember', action.payload.user["https://accounts.ikea.com/groups"].includes(unionReportGroup) ? 'true' : 'false');
      // sessionStorage.setItem('spoofIsOCCAdmin', action.payload.user["https://accounts.ikea.com/groups"].includes(occAdminGroup) ? 'true' : 'false');

      let spoofCdsUserId = action.payload.cdsData.userId;
      let spoofIsOCCManager = action.payload.cdsData.roles.includes(
        state.occPrivilege
      );
      // let spoofIsPCMember = action.payload.cdsData.department === pcRole ? true : false;
      let spoofIsPCMember =
        action.payload.cdsData.jobTitle === pcRole ||
        action.payload.cdsData.jobTitle === pcRole2
          ? true
          : false;
      // let spoofIsUnionMember = action.payload.user["https://accounts.ikea.com/groups"].includes(unionReportGroup);
      // let spoofIsOCCAdmin = action.payload.user["https://accounts.ikea.com/groups"].includes(occAdminGroup);
      let spoofIsGeneralEmployee = action.payload.cdsData.roles.includes(occGEPrivilege);
      let spoofCdsData = action.payload.cdsData;
      return {
        ...state,
        cdsData: spoofCdsData,
        cdsUserId: spoofCdsUserId,
        spoofCdsUserId,
        spoofCdsRoles: action.payload.cdsData.roles,
        spoofIsOCCManager,
        spoofIsPCMember,
        spoofIsGeneralEmployee
        // spoofIsUnionMember,
        // spoofIsOCCAdmin
      };
    }
    case SET_CDS_USER_ID: {
      sessionStorage.setItem("cdsUserId", action.payload);
      let cdsUserId = action.payload;
      return {
        ...state,
        cdsUserId,
      };
    }
    case TOGGLE_CDS_SPOOFING:
      return {
        ...state,
        isSpoofingEnabled: action.payload,
      };
    case SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case LOGOUT:
      sessionStorage.setItem("cdsUserId", "");
      sessionStorage.setItem("isOCCManager", "false");
      sessionStorage.setItem("isPCMember", "false");
      sessionStorage.setItem("isUnionMember", "false");
      sessionStorage.setItem("isOCCAdmin", "false");

      return {
        ...state,
        cdsUserId: "",
        cdsRoles: [],
        isOCCManager: false,
        isPCMember: false,
        isUnionMember: false,
        isOCCAdmin: false,
      };
    case GET_AUTH0_TOKEN:
      return {
        ...state,
        auth0token: action.payload,
      };
    case GET_APIM_TOKEN: 
      return {
        ...state,
        APIMtoken: action.payload,
      }
    case SET_USER_EMAIL:
      return {
        ...state,
        userEmail: action.payload,
      }
    case SET_USER_GROUPS:
      return {
        ...state,
        userGroup: action.payload,
      }
    default:
      return state;
  }
};

export default reducer;
