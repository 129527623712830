export const CSH_SET_FETCHING_FLAG = "CSH_SET_FETCHING_FLAG";

export const CSH_GET_ALL_EVENTS = "CSH_GET_ALL_EVENTS";
export const CSH_GET_ALL_USER_PERMISSIONS = "CSH_GET_ALL_USER_PERMISSIONS";
export const CSH_SET_SERVICE_PREDICTIONS = "CSH_SET_SERVICE_PREDICTIONS";
export const CSH_SET_RECENT_CHANGES = "CSH_SET_RECENT_CHANGES";
export const CSH_SET_RECENT_INCIDENTS = "CSH_SET_RECENT_INCIDENTS";
export const CSH_GET_ILERT_TEAMS = "CSH_GET_ILERT_TEAMS";
export const CSH_GET_ILERT_SHIFTS = "CSH_GET_ILERT_SHIFTS";
export const CSH_SELECTED_ILERT_SHIFT = "CSH_SELECTED_ILERT_SHIFT";
export const CSH_ADD_PARTICIPANT = "CSH_ADD_PARTICIPANT";

export const CSH_GET_CURRENT_ROOM = "CSH_GET_CURRENT_ROOM";
export const CSH_GET_CURRENT_ROOM_TIMELINE = "CSH_GET_CURRENT_ROOM_TIMELINE";
export const CSH_GET_SFA_REPORT = "CSH_GET_SFA_REPORT";
export const CSH_SET_CONFLUENCE_PAGE = "CSH_SET_CONFLUENCE_PAGE";
export const CSH_GET_SPLUNK_EVENTS = "CSH_GET_SPLUNK_EVENTS";
export const CSH_GET_PREDICTIONS = "CSH_GET_PREDICTIONS";
export const CSH_SET_TOAST_VISIBLE = "CSH_SET_TOAST_VISIBLE";
export const SET_IS_EDIT_SFA_REPORT = "SET_IS_EDIT_SFA_REPORT";
export const CSH_GET_BRIDGE_PARTICIPANTS = "CSH_GET_BRIDGE_PARTICIPANTS";
export const CSH_SET_AVAILABLE_BCI_IDS = "CSH_SET_AVAILABLE_BCI_IDS";
export const CSH_SET_RESERVED_BCI_IDS = "CSH_SET_RESERVED_BCI_IDS";
export const CSH_GET_CURRENT_WEEK_TEAM = "CSH_GET_CURRENT_WEEK_TEAM";
export const BROADCAST_TO_SLACK = "BROADCAST_TO_SLACK";
export const CSH_GET_GITHUB_RECOMMENDATIONS = "CSH_GET_GITHUB_RECOMMENDATIONS";
export const CSH_GET_TOP_10_CI = "CSH_GET_TOP_10_CI";
export const CSH_GET_ALL_DATAFICATION = "CSH_GET_ALL_DATAFICATION";
export const CSH_GET_DATAFICATION_BY_ID = "CSH_GET_DATAFICATION_BY_ID";
export const CSH_GET_DATAFICATION_VALIDATE = "CSH_GET_DATAFICATION_VALIDATE";
export const CSH_NOTIFICATION_CHANNEL_STATUS = "CSH_NOTIFICATION_CHANNEL_STATUS";
export const CSH_GET_HEATMAP = "CSH_GET_HEATMAP";