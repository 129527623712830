import {
  CSH_GET_ALL_EVENTS,
  CSH_GET_ALL_USER_PERMISSIONS,
  CSH_SET_FETCHING_FLAG,
  CSH_SET_SERVICE_PREDICTIONS,
  CSH_SET_RECENT_CHANGES,
  CSH_SET_RECENT_INCIDENTS,
  CSH_GET_ILERT_TEAMS,
  CSH_GET_ILERT_SHIFTS,
  CSH_SELECTED_ILERT_SHIFT,
  CSH_ADD_PARTICIPANT,
  CSH_GET_CURRENT_ROOM,
  CSH_GET_CURRENT_ROOM_TIMELINE,
  CSH_GET_SFA_REPORT,
  CSH_SET_CONFLUENCE_PAGE,
  CSH_SET_TOAST_VISIBLE,
  CSH_GET_PREDICTIONS,
  CSH_GET_SPLUNK_EVENTS,
  SET_IS_EDIT_SFA_REPORT,
  CSH_GET_BRIDGE_PARTICIPANTS,
  CSH_SET_AVAILABLE_BCI_IDS,
  CSH_SET_RESERVED_BCI_IDS,
  CSH_GET_CURRENT_WEEK_TEAM,
  CSH_GET_GITHUB_RECOMMENDATIONS,
  CSH_GET_TOP_10_CI,
  CSH_GET_ALL_DATAFICATION,
  CSH_GET_DATAFICATION_BY_ID,
  CSH_GET_DATAFICATION_VALIDATE,
  CSH_NOTIFICATION_CHANNEL_STATUS,
  CSH_GET_HEATMAP
} from "./types";

const impactedMarketList = [
  "Australia",
  "Austria",
  "Bahrain",
  // "Belarus",
  "Belgium",
  "Bulgaria",
  "Canada",
  "Chile",
  "China",
  "Croatia",
  "Cyprus",
  "Czech Republic",
  "Denmark",
  "Dominican Republic",
  "Egypt",
  "Finland",
  "France",
  "Germany",
  "Greece",
  "Hong Kong",
  "Hungary",
  "Iceland",
  "India",
  "Indonesia",
  "Ireland",
  "Israel",
  "Italy",
  "Japan",
  "Jordan",
  "Kuwait",
  "Latvia",
  "Lithuania",
  "Macao",
  "Malaysia",
  "Mallorca",
  "Mexico",
  "Morocco",
  "Netherlands",
  "Norway",
  "Oman",
  "Philippines",
  "Poland",
  "Portugal",
  "Puerto Rico",
  "Qatar",
  "Romania",
  // "Russia",
  "Saudi Arabia",
  "Serbia",
  "Singapore",
  "Slovakia",
  "Slovenia",
  "South Korea",
  "Spain",
  "Sweden",
  "Switzerland",
  "Taiwan",
  "Tenerife/Lanzarote/Gran Canaria",
  "Thailand",
  "Turkey",
  "UK_IE",
  "USA",
  // "Ukraine",
  "United Arab Emirates",
];

const initialState = {
  eventsList: [],
  isFetchingEventsList: false,
  userPermissions: [],
  isFetchingUserPermissions: false,
  predictedServices: "",
  servicePrediction: {},
  isFetchingPredictedServices: false,
  changeInformation: "",
  incidentInformation: "",
  ilertTeams: [],
  isFetchingIlertTeams: false,
  ilertShifts: [],
  isFetchingSelectedShift: false,
  selectedIlertShift: {},
  participiantsOptions: [],
  currentRoom: {},
  isFetchingCurrentRoom: false,
  currentRoomTimeline: [],
  isFetchingCurrentRoomTimeline: false,
  sfaReport: {
    action: "",
    bciId: "",
    created_on: "",
    environment: "",
    summary: {
      greetingMessage: "",
      ideskInfo: "",
      MBCIOpen: "",
      MBCIClosed: "",
      Issuedby: "",
      SFADate: "",
      impactDescription: "",
      impactEndDate: "",
      impactStartDate: "",
      impactedMarkets: [],
      status: "",
      statusDescription: "",
      timeZone: "",
      title: "",
      typeOfCriticalEvent: "",
    },
    rca: {
      rc: "",
      rcaaccountable: "",
      related: "",
      failingreason: "",
      rcarea: "",
      resolution: "",
      comments: "",
    },
    busimpact: {
      saleslostonline: "",
      orderdroponline: "",
      salesloststores: "",
      orderdropstores: "",
      unforeseencost: "",
      impactedflow: "",
      recoverytime: "",
      bicomments: "",
      brandvalue: "",
      impactedservices: "",
    },
    timeline: {
      actions: [],
      involved: [],
    },
    timelinetable: {
      assignee1: "",
      status1: "",
      action2: "",
      assignee2: "",
      status2: "",
      action3: "",
      assignee3: "",
      status3: "",
      action4: "",
      assignee4: "",
      status4: "",
      action5: "",
      assignee5: "",
      status5: "",
      action6: "",
      assignee6: "",
      status6: "",
      lessons: "",
      lassignee: "",
      lstatus: "",
    },
    modeOfCommunication: ["Slack", "Teams", "Confluence"],
    ilertShifts: [],
    ilertTeams: [],
    id: "",
    last_modified_on: "",
    notificationReferenceId: "",
    changeInformation: "",
    participiantsOptions: [],
    predictedServices: "",
    selectedIlertShift: {},
    uuid: "",
  },
  isFetchingSFAReport: false,
  impactedMarketList: impactedMarketList,
  confluencePage: "",
  isPostingToconfluence: false,
  toastVisible: false,
  toastText: "",
  currentPredictions: {},
  isFetchingCurrentPredictions: false,
  splunkEvents: [],
  isFetchingSplunkEvents: false,
  isFetchingBridgeParticipants: false,
  bridgeParticipants: {},
  isEditSFAReport: false,
  availableBCIIDs: [],
  isFetchingAvailableBCIIDs: false,
  reserverdBCIIDs: [],
  isFetchingReservedBCIIDs: false,
  currentWeekTeam: {},
  isFetchingCurrentWeekTeam: false,
  githubEvents: [],
  isFetchingGithubEvents: false,
  top10CriticalIncs: [],
  isFetchingtop10CriticalIncs: false,
  dataficationAllData: [],
  isFetchingDataficationAllRows: false,
  dataficationCurrentReport: {},
  isFetchingDataficationCurrentReport: false,
  dataficationValidate: {
    showSteps: false,
    prepopulate: false,
    documentExists: false,
  },
  isFetchingDataficationValidate: false,
  notificationChannelStatus: [],
  isFetchingHeatmap: false,
  heatmap: [],
};

const reducer = (state = initialState, action) => {
  const type = action.type;

  switch (type) {
    case CSH_GET_ALL_EVENTS: {
      return {
        ...state,
        eventsList: action.payload,
      };
    }
    case CSH_GET_ALL_USER_PERMISSIONS: {
      return {
        ...state,
        userPermissions: action.payload,
      };
    }
    case CSH_GET_CURRENT_WEEK_TEAM: {
      return {
        ...state,
        currentWeekTeam: action.payload,
      };
    }
    case CSH_SET_SERVICE_PREDICTIONS: {
      return {
        ...state,
        predictedServices: action.payload?.predictedService,
        servicePrediction: action.payload?.servicePrediction,
      };
    }
    case CSH_SET_RECENT_CHANGES: {
      return {
        ...state,
        changeInformation: action.payload,
      };
    }
    case CSH_SET_RECENT_INCIDENTS: {
      return {
        ...state,
        incidentInformation: action.payload,
      };
    }
    case CSH_GET_ILERT_TEAMS: {
      return {
        ...state,
        ilertTeams: action.payload,
      };
    }
    case CSH_GET_ILERT_SHIFTS: {
      return {
        ...state,
        ilertShifts: action.payload,
      };
    }
    case CSH_SELECTED_ILERT_SHIFT: {
      return {
        ...state,
        selectedIlertShift: action.payload,
      };
    }
    case CSH_ADD_PARTICIPANT: {
      if (action.payload.length === 0) {
        return {
          ...state,
          participiantsOptions: [],
        };
      } else {
        return {
          ...state,
          participiantsOptions: [...state.participiantsOptions, action.payload],
        };
      }
    }
    case CSH_GET_CURRENT_ROOM: {
      return {
        ...state,
        currentRoom: action.payload,
      };
    }
    case CSH_GET_CURRENT_ROOM_TIMELINE: {
      return {
        ...state,
        currentRoomTimeline: action.payload,
      };
    }
    case CSH_GET_SFA_REPORT: {
      if (Object.keys(action.payload).length !== 0) {
        return {
          ...state,
          sfaReport: action.payload,
          confluencePage:
            action?.payload?.["confluence_url"] &&
            action?.payload?.["confluence_url"] !== ""
              ? action?.payload?.["confluence_url"]
              : "",
          confluenceError: "",
          isEditSFAReport: false,
        };
      } else {
        return {
          ...state,
        };
      }
    }
    case "CSH_UPDATE_SFA_REPORT": {
      return {
        ...state,
        sfaReport: action.payload,
      };
    }
    case CSH_SET_CONFLUENCE_PAGE: {
      return {
        ...state,
        confluencePage: action.payload.url,
        confluenceError: action.payload.error,
        toastVisible: true,
      };
    }
    case CSH_SET_TOAST_VISIBLE: {
      return {
        ...state,
        toastVisible: action.payload?.visible,
        toastText: action.payload?.message,
      };
    }
    case CSH_GET_PREDICTIONS: {
      return {
        ...state,
        currentPredictions: action.payload,
      };
    }
    case CSH_GET_SPLUNK_EVENTS: {
      return {
        ...state,
        splunkEvents: action.payload,
      };
    }
    case SET_IS_EDIT_SFA_REPORT: {
      return {
        ...state,
        isEditSFAReport: action.payload,
      };
    }
    case CSH_GET_BRIDGE_PARTICIPANTS: {
      return {
        ...state,
        bridgeParticipants: action.payload,
      };
    }
    case CSH_SET_AVAILABLE_BCI_IDS: {
      return {
        ...state,
        availableBCIIDs: action.payload,
      };
    }
    case CSH_SET_RESERVED_BCI_IDS: {
      return {
        ...state,
        reserverdBCIIDs: action.payload,
      };
    }
    case CSH_GET_GITHUB_RECOMMENDATIONS: {
      return {
        ...state,
        githubEvents: action.payload,
      };
    }
    case CSH_GET_TOP_10_CI: {
      return {
        ...state,
        top10CriticalIncs: action.payload,
      };
    }
    case CSH_GET_ALL_DATAFICATION: {
      return {
        ...state,
        dataficationAllData: action.payload,
      };
    }
    case CSH_GET_DATAFICATION_BY_ID: {
      return {
        ...state,
        dataficationCurrentReport: action.payload,
      };
    }
    case CSH_GET_DATAFICATION_VALIDATE: {
      return {
        ...state,
        dataficationValidate: action.payload,
      };
    }
    case CSH_NOTIFICATION_CHANNEL_STATUS: {
      return {
        ...state,
        notificationChannelStatus: action.payload,
      };
    }
    case CSH_GET_HEATMAP: {
      return {
        ...state,
        heatmap: action.payload,
      }
    }
    case CSH_SET_FETCHING_FLAG: {
      let apiType = action.payload.apiType;
      let value = action.payload.value;
      switch (apiType) {
        case "getAllEvents": {
          return {
            ...state,
            isFetchingEventsList: value,
          };
        }
        case "getAllUserPermissions": {
          return {
            ...state,
            isFetchingUserPermissions: value,
          };
        }
        case "getPredictedService": {
          return {
            ...state,
            isFetchingPredictedServices: value,
          };
        }
        case "getIlertTeams": {
          return {
            ...state,
            isFetchingIlertTeams: value,
          };
        }
        case "setSelectedShift": {
          return {
            ...state,
            isFetchingSelectedShift: value,
          };
        }
        case "getCurrentRoomDetails": {
          return {
            ...state,
            isFetchingCurrentRoom: value,
          };
        }
        case "getCurrentRoomTimeline": {
          return {
            ...state,
            isFetchingCurrentRoomTimeline: value,
          };
        }
        case "getSFAReport": {
          return {
            ...state,
            isFetchingSFAReport: value,
          };
        }
        case "postToConfluence": {
          return {
            ...state,
            isPostingToconfluence: value,
          };
        }
        case "saveEvent": {
          return {
            ...state,
            isSavingEvent: value,
          };
        }
        case "getPredictions": {
          return {
            ...state,
            isFetchingCurrentPredictions: value,
          };
        }
        case "getSplunkEvents": {
          return {
            ...state,
            isFetchingSplunkEvents: value,
          };
        }
        case "saveSFAReport": {
          return {
            ...state,
            isSavingSFAReport: value,
          };
        }
        case "updatePeople": {
          return {
            ...state,
            isUpdatingPeople: value,
          };
        }
        case "getTeamsBridgeParticipants": {
          return {
            ...state,
            isFetchingParticipants: value,
          };
        }
        case "getAvailableBCIIds": {
          return {
            ...state,
            isFetchingAvailableBCIIDs: value,
          };
        }
        case "getReservedBCIIds": {
          return {
            ...state,
            isFetchingReservedBCIIDs: value,
          };
        }
        case "getCurrentWeekTeam": {
          return {
            ...state,
            isFetchingCurrentWeekTeam: value,
          };
        }
        case "getGithubRecommendations": {
          return {
            ...state,
            isFetchingGithubEvents: value,
          };
        }
        case "getTop10CriticalIncs": {
          return {
            ...state,
            isFetchingtop10CriticalIncs: value,
          };
        }
        case "getAllDataficationRows": {
          return {
            ...state,
            isFetchingDataficationAllRows: value,
          };
        }
        case "getDataficationRowByID": {
          return {
            ...state,
            isFetchingDataficationCurrentReport: value,
          };
        }
        case "validateNewDataficationReport": {
          return {
            ...state,
            isFetchingDataficationValidate: value,
          };
        }
        case "getNotificationStatus": {
          return {
            ...state,
            fetchingNotificationStatus: value,
          };
        }
        case "getHeatmapData": {
          return {
            ...state,
            isFetchingHeatmap: value,
          }
        }
        default:
          return { ...state };
      }
    }
    default:
      return { ...state };
  }
};

export default reducer;
