export const GET_CURRENT_SCHEDULES = "GET_CURRENT_SCHEDULES";
export const GET_PAST_SCHEDULES = "GET_PAST_SCHEDULES";
export const APPROVE_SCHEDULES = "APPROVE_SCHEDULES";
export const DENY_SCHEDULES = "DENY_SCHEDULES";
export const GET_APPROVED_SCHEDULES = "GET_APPROVED_SCHEDULES";
export const PROCESS_SCHEDULES = "PROCESS_SCHEDULES";
export const GET_PROCESSED_SCHEDULES = "GET_PROCESSED_SCHEDULES";
export const GET_UNION_REPORT = "GET_UNION_REPORT";
export const SET_FETCHING= "SET_FETCHING";
export const GET_DISPUTED_SCHEDULES = "GET_DISPUTED_SCHEDULES";
export const GET_DENIED_SCHEDULES = "GET_DENIED_SCHEDULES";
export const GET_ILERT_SCHEDULES = "GET_ILERT_SCHEDULES";
export const SET_FETCHING_FLAG = "SET_FETCHING_FLAG";
export const GET_SCHEDULED_TEAMS = "GET_SCHEDULED_TEAMS";
export const GET_PAST_ENTRIES = "GET_PAST_ENTRIES";
export const ADD_ENTRY = "ADD_ENTRY";
export const OCC_SET_TOAST_VISIBLE = "OCC_SET_TOAST_VISIBLE";
export const SET_OCC_FILTER_PERIOD = "SET_OCC_FILTER_PERIOD";
export const SET_OCC_CA_FILTER_PERIOD = "SET_OCC_CA_FILTER_PERIOD";
export const SET_OCC_ILERT_FILTER_PERIOD = "SET_OCC_ILERT_FILTER_PERIOD";
export const SET_OCC_PA_FILTER_PERIOD = "SET_OCC_PA_FILTER_PERIOD";
export const SET_OCC_DISPUTED_FILTER_PERIOD = "SET_OCC_DISPUTED_FILTER_PERIOD";
export const SET_OCC_DENIED_FILTER_PERIOD = "SET_OCC_DENIED_FILTER_PERIOD";


