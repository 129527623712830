import {
  FEEDBACK_SUBMIT_REQUEST,
  FEEDBACK_SUBMIT_SUCCESS,
  FEEDBACK_SUBMIT_FAILED,
  RESET_FAILURE_FLAG
} from "./types";

const initialState = {
  inProgress: false,
  reviews: [],
  successCount: 0,
  failureCount: 0,
  error: false,
  errorMessage: "",
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FEEDBACK_SUBMIT_REQUEST:
      return {
        ...state,
        inProgress: true,
        reviews: [],
        successCount: 0,
        failureCount: 0,
        error: false,
        errorMessage: ""
      }
    case FEEDBACK_SUBMIT_SUCCESS: 
    return {
        ...state,
        inProgress: false,
        reviews: [],
        successCount: 0,
        failureCount: 0,
        error: false,
        errorMessage: ""
    }
    case FEEDBACK_SUBMIT_FAILED:
        return {
            ...state,
            inProgress: false,
        reviews: [],
        successCount: 0,
        failureCount: 0,
        error: true,
        errorMessage: action.payload,
        }
    case RESET_FAILURE_FLAG:
        return {
            ...state,
            error: false,
            errorMessage: ''
        }
    default:
      return state;
  }
};

export default reducer;
