import { GET_MIDDLEWARE_ARTIFACT_TOTAL_LIST } from "./types";
import { GET_MIDDLEWARE_ARTIFACT_LIST } from "./types";
import {
  SET_MIDDLEWARE_FETCHING_FLAG,
  GET_MIDDLEWARE_FILTERS_LIST,
  GET_MIDDLEWARE_ASSETS_SERVER_LIST,
  SET_MIDDLEWARE_ORGANIZATION,
  SET_MIDDLEWARE_VIEW_TYPE,
  GET_MIDDLEWARE_ASSETS_MONTHLY_TRENDS,
  GET_MIDDLEWARE_ASSETS_STATS_PLATFORM_VS_TECH,
  GET_MIDDLEWARE_ASSETS_STATS_GEO_VS_DC,
  GET_MIDDLEWARE_ASSETS_STATS_HW_MODELS,
  GET_MIDDLEWARE_ASSETS_ENV_STATS,
  SET_MIDDLEWARE_FILTERS,
  RESET_MIDDLEWARE_FILTERS,
  GET_MIDDLEWARE_STORAGE_SERVER_LIST,
  GET_MIDDLEWARE_STORAGE_MONTHLY,
} from "./types";

const initialState = {
  viewType: "Assets",
  appliedFilters: {},
  isFetchingFiltersList: false,
  filtersList: [],
  isFetchingServerList: false,
  serverList: [],
  isFetchingPlatformVsTech: false,
  platformVsTechList: [],
  isFetchingHWModelList: false,
  hwModelList: [],
  isFetchingGeoVsDC: false,
  geoVsDCList: [],
  isFetchingAssetsEnvStats: false,
  assetsEnvStats: [],
  isFetchingServerMonthly: false,
  serverMonthly: [],
  isFetchingStorageServerList: false,
  storageServerList: [],
  isFetchingStorageServerMonthly: false,
  storageServerMonthly: [],
  isFetchingArtifactServerList: false,
  artifactServerList: [],
  isFetchingArtifactTotalList: false,
  artifactTotalList: [],
};

const reducer = (state = initialState, action) => {
  const type = action.type;

  switch (type) {
    case GET_MIDDLEWARE_FILTERS_LIST: {
      const initAppliedFilters = action.payload.reduce((acc, current) => {
        return { ...acc, [current.name]: "All" };
      }, {});
      return {
        ...state,
        filtersList: action.payload,
        appliedFilters: initAppliedFilters,
      };
    }

    case RESET_MIDDLEWARE_FILTERS: {
      let filters = {};
      Object.keys(state.appliedFilters).forEach((key) => {
        filters[key] = "All";
      });
      return {
        ...state,
        appliedFilters: filters,
      };
    }

    case GET_MIDDLEWARE_ASSETS_SERVER_LIST: {
      return {
        ...state,
        serverList: action.payload,
      };
    }
    case GET_MIDDLEWARE_ASSETS_MONTHLY_TRENDS: {
      return {
        ...state,
        serverMonthly: action.payload,
      };
    }
    case GET_MIDDLEWARE_ASSETS_STATS_PLATFORM_VS_TECH: {
      return {
        ...state,
        platformVsTechList: action.payload,
      };
    }
    case GET_MIDDLEWARE_ASSETS_STATS_GEO_VS_DC: {
      return {
        ...state,
        geoVsDCList: action.payload,
      };
    }

    case GET_MIDDLEWARE_ASSETS_STATS_HW_MODELS: {
      return {
        ...state,
        hwModelList: action.payload,
      };
    }
    case GET_MIDDLEWARE_ASSETS_ENV_STATS: {
      return {
        ...state,
        assetsEnvStats: action.payload,
      };
    }

    case GET_MIDDLEWARE_STORAGE_SERVER_LIST: {
      return {
        ...state,
        storageServerList: action.payload,
      };
    }

    case GET_MIDDLEWARE_STORAGE_MONTHLY: {
      return {
        ...state,
        storageServerMonthly: action.payload,
      };
    }

    case GET_MIDDLEWARE_ARTIFACT_LIST:{
      return {
        ...state,
        artifactServerList: action.payload,
      };
    }

    case GET_MIDDLEWARE_ARTIFACT_TOTAL_LIST:{
      return {
        ...state,
        artifactTotalList: action.payload,
      };
    }

    case SET_MIDDLEWARE_VIEW_TYPE: {
      return {
        ...state,
        viewType: action.payload,
      };
    }

    case SET_MIDDLEWARE_ORGANIZATION: {
      return {
        ...state,
        selectedOrganization: action.payload,
      };
    }
    case SET_MIDDLEWARE_FILTERS: {
      const { name, value } = action.payload;
      return {
        ...state,
        appliedFilters: { ...state.appliedFilters, [name]: value },
      };
    }
    case SET_MIDDLEWARE_FETCHING_FLAG: {
      const { apiType, value } = action.payload;
      switch (apiType) {
        case "getFilterList": {
          return {
            ...state,
            isFetchingFiltersList: value,
          };
        }
        case "getListOfServer": {
          return {
            ...state,
            isFetchingServerList: value,
          };
        }
        case "getAssetsServerMonthlyTrend": {
          return {
            ...state,
            isFetchingServerMonthly: value,
          };
        }
        case "getAssetsStatPlatformVsTech": {
          return {
            ...state,
            isFetchingPlatformVsTech: value,
          };
        }
        case "getAssetsStatGeoVsDC": {
          return {
            ...state,
            isFetchingGeoVsDC: value,
          };
        }
        case "getAssetsStatHardwareModels": {
          return {
            ...state,
            isFetchingHWModelList: value,
          };
        }
        case "getAssetEnvironmentStats": {
          return {
            ...state,
            isFetchingAssetsEnvStats: value,
          };
        }
        case "getStorageServerList": {
          return {
            ...state,
            isFetchingStorageServerList: value,
          };
        }
        case "getStorageMonthlyTrends": {
          return {
            ...state,
            isFetchingStorageServerMonthly: value,
          };
        }
        case "getArtifactsServerList": {
          return {
            ...state,
            isFetchingArtifactServerList: value,
          };
        }

        default:
          return { ...state };
      }
    }
    default:
      return { ...state };
  }
};

export default reducer;
