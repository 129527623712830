import {
  SET_SM_PLATFORM,
  SET_SM_OS_TYPE,
  SET_SM_SELECTED_CORE_BUSINESS,
  SET_SM_SELECTED_SOLUTION_AREA,
  GET_SM_CORE_BUSINESS_LIST,
  GET_SM_SOLUTION_AREA,
  SET_SM_SELECTED_SERVICE,
  GET_SM_SERVICES_LIST,
  SET_SM_SELECTED_ENV,
  GET_SM_ENV_LIST,
  GET_SM_SERVERS_LIST,
  GET_SM_SERVERS_LIST_BY_VERSION,
  GET_SM_OVERVIEW_METRICS,
  GET_SM_HIERARCHICAL_DATA,
  GET_SM_TOP20_UNSUPPORTED,
  GET_SM_SERVERS_LIST_BY_ENV,
  GET_SM_PATCH_TIMELINE,
  GET_SM_SWARM_DATA,
  SET_SM_FETCHING_FLAG,
  SET_SM_SERVICE_BUCKET,
  SET_SM_UNIQUE_SERVICES,
  GET_MAPPING_REQUESTS,
  SET_MAPPING_STATUS,
  GET_SERVICE_TAG_REQUESTS,
  SET_SERVICE_MAPPING_HISTORY
} from "./types";

const initialState = {
  viewType: "osvisualization",
  selectedPlatform: 0,
  selectedOsType: "Linux",
  selectedCoreBusiness: "All",
  selectedSolutionArea: "All",
  selectedEnv: "All",
  selectedService: "All",
  coreBusinessList: [],
  isFetchingCoreBusinessList: false,
  solutionAreaList: [],
  isFetchingSolutionArea: false,
  envList: [],
  isFetchingEnvList: false,
  servicesList: [],
  isFetchingServicesList: false,
  serversList: [],
  isFetchingServersList: false,
  serversListByVersion: [],
  isFetchingServersListByVersion: false,
  overviewMetrics: {},
  isFetchingOverviewMetrics: false,
  hierarchicalData: {},
  isFetchingHierarchicalData: false,
  top20Unsupported: [],
  isFetchingTop20Unsupported: false,
  serversListByEnv: [],
  isFetchingServersListByEnv: false,
  patchTimeline: [{ data: [], timePeriod: "12W" }],
  isFetchingPatchTimeline: false,
  swarmData: {},
  isFetchingSwarmData: false,
  serviceMappingBucket: [],
  uniqueServices: [],
  uniqueServers: [],
  uniqueOrphanServers: [],
  orphanServers: [],
  
  serviceMappingRequests: [],
  serviceTagRequests: [],

  serverBucket: [],
  serviceBucket: [],

  historyData: [],
};

const reducer = (state = initialState, action) => {
  const type = action.type;

  switch (type) {
    case SET_SM_PLATFORM: {
      return {
        ...state,
        selectedPlatform: action.payload,
      };
    }
    case SET_SM_OS_TYPE: {
      return {
        ...state,
        selectedOsType: action.payload,
      };
    }
    case SET_SM_SELECTED_CORE_BUSINESS: {
      return {
        ...state,
        selectedCoreBusiness: action.payload,
      };
    }
    case GET_SM_CORE_BUSINESS_LIST: {
      return {
        ...state,
        coreBusinessList: action.payload,
      };
    }
    case SET_SM_SELECTED_SOLUTION_AREA: {
      return {
        ...state,
        selectedSolutionArea: action.payload,
      };
    }
    case GET_SM_SOLUTION_AREA: {
      return {
        ...state,
        solutionAreaList: action.payload,
      };
    }

    case SET_SM_SELECTED_SERVICE: {
      return {
        ...state,
        selectedService: action.payload,
      };
    }
    case GET_SM_SERVICES_LIST: {
      return {
        ...state,
        servicesList: action.payload,
      };
    }
    case SET_SM_SELECTED_ENV: {
      return {
        ...state,
        selectedEnv: action.payload,
      };
    }
    case GET_SM_ENV_LIST: {
      return {
        ...state,
        envList: action.payload,
      };
    }
    case GET_SM_SERVERS_LIST: {
      return {
        ...state,
        serversList: action.payload,
      };
    }
    case GET_SM_SERVERS_LIST_BY_VERSION: {
      return {
        ...state,
        serversListByVersion: action.payload,
      };
    }
    case GET_SM_OVERVIEW_METRICS: {
      return {
        ...state,
        overviewMetrics: action.payload,
      };
    }
    case GET_SM_HIERARCHICAL_DATA: {
      return {
        ...state,
        hierarchicalData: action.payload,
      };
    }
    case GET_SM_TOP20_UNSUPPORTED: {
      return {
        ...state,
        top20Unsupported: action.payload,
      };
    }
    case GET_SM_SERVERS_LIST_BY_ENV: {
      return {
        ...state,
        serversListByEnv: action.payload,
      };
    }
    case GET_SM_PATCH_TIMELINE: {
      return {
        ...state,
        patchTimeline: action.payload,
      };
    }
    case GET_SM_SWARM_DATA: {
      return {
        ...state,
        swarmData: action.payload,
      };
    }

    case SET_SM_SERVICE_BUCKET: {
      return {
        ...state,
        serviceMappingBucket: action.payload.buckets,
        serverBucket: action.payload.serverBucket,
        serviceBucket: action.payload.serviceBucket,
        orphanServers: action.payload.orphanServers
      };
    }

    case SET_SM_UNIQUE_SERVICES: {
      return {
        ...state,
        uniqueServices: action.payload.uniqueServices,
        uniqueServers: action.payload.uniqueServers,
        uniqueOrphanServers: action.payload.uniqueOrphanServers,
      };
    }

    case GET_MAPPING_REQUESTS: {
      return {
        ...state,
        serviceMappingRequests: action.payload
      };
    }

    case SET_MAPPING_STATUS: {
      return {
        ...state,
        showMappingStatus: action.payload?.show,
        mappingMessage: action.payload?.message
      }
    }

    case GET_SERVICE_TAG_REQUESTS: {
      return {
        ...state,
        serviceTagRequests: action.payload
      }
    }

    case SET_SERVICE_MAPPING_HISTORY: {
      return {
        ...state,
        historyData: action.payload
      }
    }

    case SET_SM_FETCHING_FLAG: {
      let apiType = action.payload.apiType;
      let value = action.payload.value;
      switch (apiType) {
        case "setMappingList": {
          return {
            ...state,
            isFetchingServiceMappingList: value,
          }
        }
        case "saveServiceMapping": {
          return {
            ...state,
            isSavingServiceMappingList: value,
          }
        }
        case "getMappingRequests": {
          return {
            ...state,
            isGettingServiceMappingRequests: value,
          }
        }
        case "approveMappingRequests": {
          return {
            ...state,
            isApprovingMappingRequests: value,
          }
        }
        case "getCoreBusinessList": {
          return {
            ...state,
            isFetchingCoreBusinessList: value,
          };
        }
        case "getSolutionAreaList": {
          return {
            ...state,
            isFetchingSolutionArea: value,
          };
        }
        case "getServicesList": {
          return {
            ...state,
            isFetchingServicesList: value,
          };
        }
        case "getEnvList": {
          return {
            ...state,
            isFetchingEnvList: value,
          };
        }
        case "getServersList": {
          return {
            ...state,
            isFetchingServersList: value,
          };
        }
        case "getServersListByVersion": {
          return {
            ...state,
            isFetchingServersListByVersion: value,
          };
        }
        case "getOverviewMetrics": {
          return {
            ...state,
            isFetchingOverviewMetrics: value,
          };
        }
        case "getHierarchicalData": {
          return {
            ...state,
            isFetchingHierarchicalData: value,
          };
        }
        case "getTop20Unsupported": {
          return {
            ...state,
            isFetchingTop20Unsupported: value,
          };
        }
        case "getServersListByEnv": {
          return {
            ...state,
            isFetchingServersListByEnv: value,
          };
        }
        case "getPatchTimeline": {
          return {
            ...state,
            isFetchingPatchTimeline: value,
          };
        }
        case "getSwarmData": {
          return {
            ...state,
            isFetchingSwarmData: value,
          };
        }
        case "changeServiceTag": {
          return {
            ...state,
            isUpdatingServiceTag: value?.loading,
            taggingService: value?.service
          };
        }
        case "getServiceTagRequests": {
          return {
            ...state,
            isGettingServiceTagRequests: value,
          }
        }
        case "getHistoryData": {
          return {
            ...state,
            isGettingHistory: value,
          }
        }
        default:
          return { ...state };
      }
    }
    default:
      return { ...state };
  }
};

export default reducer;
