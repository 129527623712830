import { createStore, applyMiddleware } from 'redux';
// import { composeWithDevTools } from 'redux-devtools-extension';
import thunkMiddleware from 'redux-thunk';
import createSagaMiddleware from 'redux-saga';
// import logger from 'redux-logger';
import rootReducer from './root';

const sagaMiddleware = createSagaMiddleware();
const preloadedState = {};
export const store = createStore(rootReducer, preloadedState,
    applyMiddleware(sagaMiddleware,thunkMiddleware)
);