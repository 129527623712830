export const GET_SH_UNASSIGNED_TICKETS = 'GET_SH_UNASSIGNED_TICKETS';
export const GET_SH_CURRENT_HANDOVER = 'GET_SH_CURRENT_HANDOVER';
export const SET_SH_FETCHING_FLAG = 'SET_SH_FETCHING_FLAG';
export const SET_SH_SELECTED_SUPPORT_AREA = 'SET_SH_SELECTED_SUPPORT_AREA';
export const GET_SH_OPERATION_LEADS = 'GET_SH_OPERATION_LEADS';
export const GET_SH_INCIDENT_DETAILS = 'GET_SH_INCIDENT_DETAILS';
export const GET_SH_INCIDENT_COUNTS = 'GET_SH_INCIDENT_COUNTS';
export const GET_SH_ONGOING_PM = 'GET_SH_ONGOING_PM'
export const GET_SH_INCIDENT_COMMENTS_HISTORY = 'GET_SH_INCIDENT_COMMENTS_HISTORY';
export const GET_SH_INCIDENT_WORKLOG_HISTORY = 'GET_SH_INCIDENT_WORKLOG_HISTORY';
export const GET_SH_ADMIN_USERS_LIST = 'GET_SH_ADMIN_USERS_LIST';
export const SET_SH_TOAST_VISIBLE = 'SET_SH_TOAST_VISIBLE';
export const GET_SH_AGENT_ROLES_LIST = 'GET_SH_AGENT_ROLES_LIST';
export const GET_SH_CDS_USER_DATA = 'GET_SH_CDS_USER_DATA';
export const GET_SH_BANNER_MESSSAGE = 'GET_SH_BANNER_MESSSAGE';
export const GET_SH_SERVICES = 'GET_SH_SERVICES';
