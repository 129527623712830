export const SET_FETCHING_FLAG = "SET_FETCHING_FLAG";
export const GET_SERVICES_LIST = "GET_SERVICES_LIST";
export const SET_SELECTED_SERVICE = "SET_SELECTED_SERVICE";
export const GET_PATCH_OVERVIEW = "GET_PATCH_OVERVIEW";
export const GET_NOT_PATCHED = "GET_NOT_PATCHED";
export const GET_TOP10SERVICES_MISSING_PATCHES = "GET_TOP10SERVICES_MISSING_PATCHES";
export const GET_SELECTED_SERVICE_OVERVIEW = "GET_SELECTED_SERVICE_OVERVIEW";
export const GET_SELECTED_SERVICE_SYSTEMS = "GET_SELECTED_SERVICE_SYSTEMS";
export const GET_TIMELINE_VIEW = "GET_TIMELINE_VIEW";
export const GET_TIMELINE_VIEW_SERVICE = "GET_TIMELINE_VIEW_SERVICE";

export const SECURITYVIZ_SET_OS_TYPE = "SECURITYVIZ_SET_OS_TYPE";
export const SECURITYVIZ_WINDOWS_SET_TYPE = "SECURITYVIZ_WINDOWS_SET_TYPE"
export const WINDOWS_GET_PATCH_OVERVIEW = "WINDOWS_GET_PATCH_OVERVIEW";
export const WINDOWS_GET_TIMEPERIODVIEW = "WINDOWS_GET_TIMEPERIODVIEW";
export const WINDOWS_TOP10SERVICES = "WINDOWS_TOP10SERVICES";
export const WINDOWS_GET_TIMELINE_VIEW = "WINDOWS_GET_TIMELINE_VIEW";
export const WINDOWS_GET_SELECTED_SERVICE_OVERVIEW = 'WINDOWS_GET_SELECTED_SERVICE_OVERVIEW';
export const WINDOWS_GET_SELECTED_SERVICE_SYSTEMS = 'WINDOWS_GET_SELECTED_SERVICE_SYSTEMS';
export const WINDOWS_GET_SELECTED_SERVICE_TIMELINE_VIEW = "WINDOWS_GET_SELECTED_SERVICE_TIMELINE_VIEW";

export const WINDOWS_GET_CVE_EXPOSED_MACHINES = "WINDOWS_GET_CVE_EXPOSED_MACHINES";
export const WINDOWS_GET_CVE_AGEWISE_SORTING = "WINDOWS_GET_CVE_AGEWISE_SORTING";
export const WINDOWS_GET_CVE_OVERVIEW = "WINDOWS_GET_CVE_OVERVIEW";
