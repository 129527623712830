export const NEGATIVE_REVIEW_REQUEST = 'NEGATIVE_REVIEW_REQUEST';
export const NEGATIVE_REVIEW_SUCCESS = 'NEGATIVE_REVIEW_SUCCESS';
export const NEGATIVE_REVIEW_FAILED = 'NEGATIVE_REVIEW_FAILED';

export const DATE_FILTER_UPDATE_REQUEST = 'DATE_FILTER_UPDATE_REQUEST';
export const DATE_FILTER_UPDATE_SUCCESS = 'DATE_FILTER_UPDATE_SUCCESS';
export const DATE_FILTER_UPDATE_FAILED = 'DATE_FILTER_UPDATE_FAILED';

export const CLEAR_FILTER = 'CLEAR_FILTER';
export const SET_ACTIVE_PAGE_PAGINATOR = 'SET_ACTIVE_PAGE_PAGINATOR';
export const SET_REVIEWS_PER_PAGE = 'SET_REVIEWS_PER_PAGE';
export const SET_SELECTED_CLASSIFIATION_TAG = 'SET_SELECTED_CLASSIFIATION_TAG';
export const SET_SELECTED_APP_VERSION_CODES ='SET_SELECTED_APP_VERSION_CODES';
export const SET_SELECTED_COUNTRIES = 'SET_SELECTED_COUNTRIES';
export const SET_SELECTED_USERRATINGS = 'SET_SELECTED_USERRATINGS';

export const SET_CLEAR_COUNTRIES_FLAG = 'SET_CLEAR_COUNTRIES_FLAG';
export const UNSET_CLEAR_COUNTRIES_FLAG = 'UNSET_CLEAR_COUNTRIES_FLAG';

export const SET_CLEAR_APPVERSION_FLAG = 'SET_CLEAR_APPVERSION_FLAG';
export const UNSET_CLEAR_APPVERSION_FLAG = 'UNSET_CLEAR_APPVERSION_FLAG';

export const SET_CLEAR_TAG_FLAG = 'SET_CLEAR_TAG_FLAG';
export const UNSET_CLEAR_TAG_FLAG = 'UNSET_CLEAR_TAG_FLAG';

export const UPDATE_REVIEW_REPLY_LIST = 'UPDATE_REVIEW_REPLY_LIST';

export const UPDATE_CHECKALL_STATUS = 'UPDATE_CHECKALL_STATUS';


