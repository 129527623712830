export const SV_SET_FETCHING_FLAG = "SV_SET_FETCHING_FLAG";
export const SV_SET_OS_TYPE = "SV_SET_OS_TYPE";
export const SV_SET_STORAGE_TYPE = "SV_SET_STORAGE_TYPE"
export const SV_SET_SELECTED_SERVICE = "SV_SET_SELECTED_SERVICE";

export const SV_GET_SERVICES_LIST = "SV_GET_SERVICES_LISTSV_GET_SERVICES_LIST";
export const SV_GET_SYSTEMS_INFO = "SV_GET_SYSTEMS_INFO";
export const SV_GET_SERVERS_LIST = "SV_GET_SERVERS_LIST";
export const SV_GET_INSIGHTS = "SV_GET_INSIGHTS";
export const SV_GET_TREND_VIZ = "SV_GET_TREND_VIZ";
export const SV_SELECTED_PLATFORM = "SV_SELECTED_PLATFORM";