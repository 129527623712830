import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";

import { getToken } from "../../redux/gitvisualization/actions";
import { getAPIMToken } from "../../redux/authorization/actions";

import LoadingWidget from "../app/Loading/LoadingWidget";

const withAPIMAuth = (Component) => (props) => {
  const [loading, setLoading] = useState(false);
  const { isAuthenticated } = useAuth0();

  const dispatch = useDispatch();
  const GitVizToken = useSelector((state) => state.gitvisualization.token);
  const CSHToken = useSelector((state) => state.at.APIMtoken);
  const auth0token = useSelector((state) => state.at.auth0token);

  useEffect(() => {
    try {
      async function fetchToken() {
        setLoading(true);
        if (isAuthenticated && auth0token !== "") {
          dispatch(getAPIMToken(auth0token));
          dispatch(getToken(auth0token));
        }
        setLoading(false);
      }
      fetchToken();
    } catch (error) {
      console.error(`error occured while getting APIM token :: ${error}`);
    }
  }, [dispatch, isAuthenticated, auth0token]);

  if (loading) {
    return <LoadingWidget />;
  } else if (GitVizToken && GitVizToken !== "" && CSHToken && CSHToken !== "") {
    return <Component {...props} />;
  } else {
    return <LoadingWidget />;
  }
};
export default withAPIMAuth;
