import {
  SET_GIT_TYPE,
  GET_SEARCH_RESULTS,
  GET_REPO_OVERVIEW,
  SET_FETCHING_FLAG,
  GET_COMMIT_FREQUENCY,
  GET_TOP_CONTRIBUTORS,
  GET_ORG_DATA,
  GET_BRANCH_OVERVIEW,
  GET_PR_OVERVIEW,
  GET_ORG_ACTIVITIES,

  GET_ORG_ACTIVITIES_LIST,
  GET_ORG_TOP_CONTRIBUTORS,
  GET_DEPLOYMENT_STATS,
  GET_DEPENDENCIES,
  SET_DATA_CONTROL,
  TOGGLE_FETCH_DATA,

  SET_TOKEN
} from "./types";

const initialState = {  
  gitType: 'github',
  fetchData: true,
  searchResults: [],
  isFetchingSearchResults: false,
  repoOverview: {},
  isFetchingRepoOverview: false,
  commitFrequency: [],
  isFetchingCommitFrequency: false,
  topContributors: [],
  isFetchingTopContributors: false,
  orgData: {},	
  // selectedDept: {},	
  selectedDepartmentname: '',
  isFetchingOrgData: false,
  orgActivities: [],
  groupedActivityData: [],
  isFetchingOrgActivityData: false,
  branchOverview: {},
  isFetchingBranchOverview: false,
  PROverview: {},
  isFetchingPROverview: false,

  allOrgActivities: [],
  isFetchingOrgActivities: false,
  orgTopContributors: [],
  isFetchingOrgTopContributors: false,
  deploymentStats: [],
  isFetchingDeploymentStats: false,
  dependencies: [],
  isFetchingDependencies: false,

  departmentList: [],
  token: '',
  isControlled: false,
  initalDepartmentList: []
};

const reducer = (state = initialState, action) => {
  const type = action.type;

  switch (type) {
    case SET_GIT_TYPE: {
      return {
        ...state,
        gitType: action.payload
      }
    }
    case SET_DATA_CONTROL: {
      return {
        ...state,
        isControlled: action.payload
      }
    }
    case TOGGLE_FETCH_DATA: {
      let value = !(state.fetchData);
      return {
        ...state,
        fetchData: value,
        selectedDepartmentname: state.selectedDept?.department
      };
    }
    case GET_SEARCH_RESULTS: {
      return {
        ...state,
        searchResults: action.payload,
      };
    }
    case GET_REPO_OVERVIEW: {
      return {
        ...state,
        repoOverview: action.payload,
      };
    }
    case GET_COMMIT_FREQUENCY: {
      return {
        ...state,
        commitFrequency: action.payload,
      };
    }
    case GET_TOP_CONTRIBUTORS: {
      return {
        ...state,
        topContributors: action.payload,
      };
    }
    case GET_BRANCH_OVERVIEW: {
      return {
        ...state,
        branchOverview: action.payload,
      }
    }
    case GET_PR_OVERVIEW: {
      return {
        ...state,
        PROverview: action.payload,
      }
    }
    case GET_ORG_DATA: {	
      return {	
        ...state,	
        selectedDept: action.payload.selectedDept,	
        orgData: action.payload.orgData, 
        departmentList: action.payload.depList,
      };	
    }
    case 'SET_DEPT_LIST': {
      return {
        ...state,
        initalDepartmentList: action.payload
      }
    }
    case 'SET_SELECTED_DEPT': {
      return {
        ...state,	
        selectedDept: action.payload
      }
    }
    case GET_ORG_ACTIVITIES: {
      return {
        ...state,
        orgActivities: action.payload.activityData,
        groupedActivityData: action.payload.groupedActivityData
      }
    }
    case GET_ORG_ACTIVITIES_LIST: {
      return {
        ...state,
        allOrgActivities: action.payload
      }
    }
    case GET_ORG_TOP_CONTRIBUTORS: {
      return {
        ...state,
        orgTopContributors: action.payload
      }
    }
    case GET_DEPLOYMENT_STATS: {
      return {
        ...state,
        deploymentStats: action.payload
      }
    }
    case GET_DEPENDENCIES: {
      return {
        ...state,
        dependencies: action.payload
      }
    }
    case SET_FETCHING_FLAG: {
      let apiType = action.payload.apiType;
      let value = action.payload.value;
      switch (apiType) {
        case "getSearchResults": {
          return {
            ...state,
            isFetchingSearchResults: value,
          };
        }
        case "getRepoOverview": {
          return {
            ...state,
            isFetchingRepoOverview: value,
          };
        }
        case "getCommitFrequency": {
          return {
            ...state,
            isFetchingCommitFrequency: value,
          };
        }
        case "getTopContributors": {
          return {
            ...state,
            isFetchingTopContributors: value,
          };
        }
        case "getBranchOverview": {
          return {
            ...state,
            isFetchingBranchOverview: value,
          }
        }
        case "getPROverview": {
          return {
            ...state,
            isFetchingPROverview: value
          }
        }
        case "getOrgData": {	
          return {	
            ...state,	
            isFetchingOrgData: value,	
          };	
        }
        case "getOrgActivityData": {	
          return {	
            ...state,	
            isFetchingOrgActivityData: value,	
          };	
        }

        case "getOrgActivities": {
          return {	
            ...state,	
            isFetchingOrgActivities: value,	
          };
        }
        case "getOrgTopContributors": {
          return {	
            ...state,	
            isFetchingOrgTopContributors: value,	
          };
        }
        case "getDeploymentStats": {
          return {
            ...state,
            isFetchingDeploymentStats: value,
          }
        }
        case "getDependencies": {
          return {
            ...state,
            isFetchingDependencies: value,
          }
        }
        default:
          return { ...state };
      }
    }
    case SET_TOKEN: {
      return {
        ...state,
        token: action.payload
      }
    }
    default:
      return { ...state };
  }
};

export default reducer;
