import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { store } from "./redux/store";
// import { Auth0Provider } from "@auth0/auth0-react";
import { SnackbarProvider } from "notistack";
import { BrowserRouter as Router } from "react-router-dom";

// import reportWebVitals from "./reportWebVitals"

import App from "./pages/App/App";
import Auth0ProviderWithHistory from "./components/routes/Auth0ProviderWithHistory";
import "./index.css";

ReactDOM.render(
  <Router>
    <Auth0ProviderWithHistory>
      <Provider store={store}>
        <SnackbarProvider max={6} preventDuplicate>
          <App />
        </SnackbarProvider>
      </Provider>
    </Auth0ProviderWithHistory>
  </Router>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(//console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log);

