import {
  SET_SELECTED_SERVICE,
  GET_SERVICES_LIST,
  SET_FETCHING_FLAG,
  GET_PATCH_OVERVIEW,
  GET_NOT_PATCHED,
  GET_TOP10SERVICES_MISSING_PATCHES,
  GET_SELECTED_SERVICE_OVERVIEW,
  GET_SELECTED_SERVICE_SYSTEMS,
  GET_TIMELINE_VIEW,
  GET_TIMELINE_VIEW_SERVICE,
  SECURITYVIZ_SET_OS_TYPE,
  WINDOWS_GET_PATCH_OVERVIEW,
  SECURITYVIZ_WINDOWS_SET_TYPE,
  WINDOWS_GET_TIMEPERIODVIEW,
  WINDOWS_TOP10SERVICES,
  WINDOWS_GET_TIMELINE_VIEW,
  WINDOWS_GET_SELECTED_SERVICE_OVERVIEW,
  WINDOWS_GET_SELECTED_SERVICE_SYSTEMS,
  WINDOWS_GET_SELECTED_SERVICE_TIMELINE_VIEW,
  WINDOWS_GET_CVE_OVERVIEW,
  WINDOWS_GET_CVE_EXPOSED_MACHINES,
  WINDOWS_GET_CVE_AGEWISE_SORTING
} from "./types";

const initialState = {
  selectedOsType: "Windows",
  selectedWindowsType: "All",
  selectedService: "",
  servicesList: [],
  isFetchingServicesList: false,
  patchOverview: {},
  isFetchingPatchOverview: false,
  notPatched: {},
  isFetchingNotPatched: false,
  top10ServicesMissingPatches: [],
  isFetchingTop10ServicesMissingPatches: false,
  selectedServiceOverview: {},
  isFetchingSelectedServiceOverview: false,
  selectedServiceSystems: [],
  isFetchingTimelineView: false,
  timelineView : [],
  isFetchingTimelineViewService: false,
  timelineViewService: [],
  isFetchingwindowsPatchOverview: false,
  windowsPatchOverview: [],
  isFetchingWindowsTimeperiodView: false,
  windowsTimeperiodView: [],
  isFetchingWindowsTop10Services: false,
  windowsTop10Services: [],
  isFetchingWindowsTimelineView: false,
  windowsTimelineView: [],
  isFetchingWindowsServiceOverview: false,
  WindowsServiceOverview: {},
  isFetchingWindowsServiceSystems: false,
  windowsServiceSystems: [],
  isFetchingWindowsServiceTimelineView: false,
  windowsServiceTimelineView: [],
  isFetchingWindowsCveOverview: false,
  windowsCveOverview: {},
  isFetchingWindowsCveExposedMachines: false,
  windowsCveExposedMachines: [],
  isFetchingWindowsCveAgeWiseSorting: false,
  windowsCveAgeWiseSorting: [],
};

const reducer = (state = initialState, action) => {
  const type = action.type;

  switch (type) {
    case SET_SELECTED_SERVICE: {
      return {
        ...state,
        selectedService: action.payload,
      };
    }
    case SECURITYVIZ_SET_OS_TYPE: {
      return {
        ...state,
        selectedOsType: action.payload
      }
    }
    case SECURITYVIZ_WINDOWS_SET_TYPE: {
      return {
        ...state,
        selectedWindowsType: action.payload
      }
    }
    case GET_SERVICES_LIST: {
      return {
        ...state,
        servicesList: action.payload,
      };
    }
    case GET_PATCH_OVERVIEW: {
      return {
        ...state,
        patchOverview: action.payload,
      };
    }
    case GET_NOT_PATCHED: {
      return {
        ...state,
        notPatched: action.payload,
      };
    }
    case GET_TOP10SERVICES_MISSING_PATCHES: {
      return {
        ...state,
        top10ServicesMissingPatches: action.payload,
      };
    }
    case GET_SELECTED_SERVICE_OVERVIEW: {
      return {
        ...state,
        selectedServiceOverview: action.payload,
      };
    }
    case GET_SELECTED_SERVICE_SYSTEMS: {
      return {
        ...state,
        selectedServiceSystems: action.payload,
      };
    }
    case GET_TIMELINE_VIEW_SERVICE: {
      return {
        ...state,
        timelineViewService: action.payload
      }
    }
    case GET_TIMELINE_VIEW: {
      return {
        ...state,
        timelineView: action.payload
      }
    }
    case WINDOWS_GET_PATCH_OVERVIEW: {
      return {
        ...state,
        windowsPatchOverview: action.payload
      }
    }
    case WINDOWS_GET_TIMEPERIODVIEW: {
      return {
        ...state,
        windowsTimeperiodView: action.payload
      }
    }
    case WINDOWS_TOP10SERVICES: {
      return {
        ...state,
        windowsTop10Services: action.payload
      }
    }
    case WINDOWS_GET_TIMELINE_VIEW: {
      return {
        ...state,
        windowsTimelineView: action.payload
      }
    }
    case WINDOWS_GET_SELECTED_SERVICE_OVERVIEW: {
      return {
        ...state,
        WindowsServiceOverview : action.payload
      }
    }
    case WINDOWS_GET_SELECTED_SERVICE_SYSTEMS: {
      return {
        ...state,
        windowsServiceSystems: action.payload
      }
    }
    case WINDOWS_GET_SELECTED_SERVICE_TIMELINE_VIEW: {
      return {
        ...state,
        windowsServiceTimelineView: action.payload
      }
    }
    case WINDOWS_GET_CVE_OVERVIEW: {
      return {
        ...state,
        windowsCveOverview: action.payload
      }
    }
    case WINDOWS_GET_CVE_EXPOSED_MACHINES: {
      return {
        ...state,
        windowsCveExposedMachines: action.payload
      }
    }
    case WINDOWS_GET_CVE_AGEWISE_SORTING: {
      return {
        ...state,
        windowsCveAgeWiseSorting: action.payload
      }
    }
    case SET_FETCHING_FLAG: {
      let apiType = action.payload.apiType;
      let value = action.payload.value;
      switch (apiType) {
        case "getServicesList": {
          return {
            ...state,
            isFetchingServicesList: value,
          };
        }
        case "getPatchOverview": {
          return {
            ...state,
            isFetchingPatchOverview: value,
          };
        }
        case "getNotPatched": {
          return {
            ...state,
            isFetchingNotPatched: value,
          };
        }
        case "getTop10ServicesMissingPatches": {
          return {
            ...state,
            isFetchingTop10ServicesMissingPatches: value,
          };
        }
        case "getSelectedServiceOverview": {
          return {
            ...state,
            isFetchingSelectedServiceOverview: value,
          };
        }
        case "getSelectedServiceSystems": {
          return {
            ...state,
            isFetchingSelectedServiceSystems: value,
          };
        }
        case "getTimelineViewByService": {
          return {
            ...state,
            isFetchingTimelineViewService: value
          }
        }
        case "getTimelineView": {
          return {
            ...state,
            isFetchingTimelineView: value
          }
        }
        case "getWindowsPatchOverview": {
          return {
            ...state,
            isFetchingwindowsPatchOverview: value
          }
        }
        case "getWindowsTimeperiodView": {
          return {
            ...state,
            isFetchingWindowsTimeperiodView: value
          }
        }
        case "getWindowsTop10Services": {
          return {
            ...state,
            isFetchingWindowsTop10Services: value
          }
        }
        case "getWindowsTimelineView": {
          return {
            ...state,
            isFetchingWindowsTimelineView: value
          }
        }
        case "getSelectedWindowsServiceOverview": {
          return {
            ...state,
            isFetchingWindowsServiceOverview: value
          }
        }
        case "getSelectedWindowsServiceSystems": {
          return {
            ...state,
            isFetchingWindowsServiceSystems: value
          }
        }
        case "getWindowsSelectedServiceTimelineView": {
          return {
            ...state,
            isFetchingWindowsServiceTimelineView: value
          }
        }
        case "getWindowsCveOverview": {
          return {
            ...state,
            isFetchingWindowsCveOverview: value
          }
        }
        case "getWindowsCveExposedMachines": {
          return {
            ...state,
            isFetchingWindowsCveOverview: value
          }
        }
        case "getWindowsCveAgeWiseSorting": {
          return {
            ...state,
            isFetchingWindowsCveAgeWiseSorting: value
          }
        }
        default:
          return { ...state };
      }
    }
    default:
      return { ...state };
  }
};

export default reducer;
