export const SAVE_EVENT = "SAVE_EVENT";
export const GET_ALL_EVENTS = "GET_ALL_EVENTS";
export const GET_RECENT_EVENTS = "GET_RECENT_EVENTS";
export const UPDATE_EVENT = "UPDATE_EVENT";
export const SELECT_EVENT = "SELECT_EVENT";
export const COPY_EVENT = "COPY_EVENT";
export const DELETE_EVENT = "DELETE_EVENT";
export const TOGGLE_LOADING = "TOGGLE_LOADING";
export const SET_IS_EDIT = "SET_IS_EDIT";
export const RESET = "RESET";
export const SET_TOAST = "SET_TOAST";
export const RESET_TOAST = "RESET_TOAST";

export const GET_ALL_USER_PERMISSION = "GET_ALL_USER_PERMISSION";
export const SAVE_PERMISSION = "SAVE_PERMISSION";
export const UPDATE_PERMISSION = "UPDATE_PERMISSION";
export const DELETE_PERMISSION = "DELETE_PERMISSION";
export const SET_PERMISSION_IS_EDIT = "SET_PERMISSION_IS_EDIT";
export const SELECT_USER_PERMISSION = "SELECT_USER_PERMISSION";
export const SET_TIMELINE_VIEW = "SET_TIMELINE_VIEW";
export const SET_TIMELINE_DATA = "SET_TIMELINE_DATA";

export const SET_SERVICE_PREDICTIONS = "SET_SERVICE_PREDICTIONS";
export const SET_RECENT_CHANGES = "SET_RECENT_CHANGES";
export const SET_RECENT_INCIDENTS = "SET_RECENT_INCIDENTS";
export const GET_ILERT_TEAMS = "GET_ILERT_TEAMS";
export const GET_ILERT_SHIFTS = "GET_ILERT_SHIFTS";
export const SELECTED_ILERT_SHIFT = "SELECTED_ILERT_SHIFT";
export const ADD_PARTICIPANT = "ADD_PARTICIPANT";