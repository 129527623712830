import {
    SAVE_EVENT,
    SET_TIMELINE_VIEW,
    GET_ALL_EVENTS,
    GET_RECENT_EVENTS,
    UPDATE_EVENT,
    SELECT_EVENT,
    DELETE_EVENT,
    TOGGLE_LOADING,
    RESET,
    SET_TOAST,
    RESET_TOAST,
    SAVE_PERMISSION,
    UPDATE_PERMISSION,
    SELECT_USER_PERMISSION,
    GET_ALL_USER_PERMISSION,
    SET_TIMELINE_DATA,
    COPY_EVENT,
    // SET_IS_EDIT,
    // SET_PERMISSION_IS_EDIT,
    // DELETE_PERMISSION,
    SET_SERVICE_PREDICTIONS,
    SET_RECENT_CHANGES,
    SET_RECENT_INCIDENTS,
    GET_ILERT_TEAMS,
    GET_ILERT_SHIFTS,
    SELECTED_ILERT_SHIFT,
    ADD_PARTICIPANT
  } from "./types";
const defaultEvent = {
  bciId: "",
  title: "",
  status: "Open",
  modeOfCommunication: [],
  typeOfCriticalEvent: "BCI",
  impactStartDate: "",
  impactEndDate: "",
  impactedMarkets: [],
  impactDescription: "",
  statusDescription: "",
  ideskInfo: "",
  timeZone: "CET",
  greetingMessage: "Best Regards, Digital Operations, IKEA Group Digital",
  participiantsOptions: [],
  predictedServices: "",
  changeInformation: "",
  incidentInformation: "",
  ilertTeams: [],
  ilertShifts: [],
  selectedIlertShift: {},
};

const initialState = {
    timeZoneList:[
        {
            label: 'CET',
            value: 'CET'
        },
        {
            label: 'CEST',
            value: 'CEST'
        },
    ],
    typeOfCriticalEventList: [
        {
            label: 'Early Warning',
            value: 'Early Warning'
        },
        {
            label: 'BCI',
            value: 'BCI'
        },
        {
            label: 'MBCI',
            value: 'MBCI'
        },
        
    ],
    modeOfCommunicationList: [
        {
            label: "Slack",
            value: "Slack",
            disabled: false
        },
        {
            label: "Teams",
            value: "Teams",
            disabled: false
        },
        {
            label: "Confluence",
            value: "Confluence",
            disabled: false
        },
        {
            label: "iSubscribe",
            value: "iSubscribe",
            disabled: false
        }
    ],
    statusList: [
        {
            label: "Open",
            value: "Open"
        },
        {
            label: "In Progress",
            value: "In Progress"
        },
        {
            label: "Closed",
            value: "Closed"
        }
    ],
    impactedMarketList: [
        "Australia",
        "Austria",
        "Bahrain",
        // "Belarus",
        "Belgium",
        "Bulgaria",
        "Canada",
        "Chile",
        "China",
        "Croatia",
        "Cyprus",
        "Czech Republic",
        "Denmark",
        "Dominican Republic",
        "Egypt",
        "Finland",
        "France",
        "Germany",
        "Greece",
        "Hong Kong",
        "Hungary",
        "Iceland",
        "India",
        "Indonesia",
        "Ireland",
        "Israel",
        "Italy",
        "Japan",
        "Jordan",
        "Kuwait",
        "Latvia",
        "Lithuania",
        "Macao",
        "Malaysia",
        "Mallorca",
        "Mexico",
        "Morocco",
        "Netherlands",
        "Norway",
        "Oman",
        "Philippines",
        "Poland",
        "Portugal",
        "Puerto Rico",
        "Qatar",
        "Romania",
        // "Russia",
        "Saudi Arabia",
        "Serbia",
        "Singapore",
        "Slovakia",
        "Slovenia",
        "South Korea",
        "Spain",
        "Sweden",
        "Switzerland",
        "Taiwan",
        "Tenerife/Lanzarote/Gran Canaria",
        "Thailand",
        "Turkey",
        "UK_IE",
        "USA",
        // "Ukraine",
        "United Arab Emirates"
    ],
    eventList: [],
    userPermissionList: [],
    recentEventList: [],
    recentUserPermissionList: [],
    selectUserPermission: {
        email: "",
        role: ""
    },
    selectedEvent: {
        bciId: "",
        title: "",
        status: "Open",
        modeOfCommunication: [],
        typeOfCriticalEvent: "BCI",
        impactStartDate: "",
        impactEndDate: "",
        impactedMarkets: [],
        impactDescription: "",
        statusDescription: "",
        ideskInfo: "",
        greetingMessage: "Best Regards, Digital Operations, IKEA Group Digital",
        timeZone: "CET"
    },
    loading: false,
    isEdit: false,
    isToastVisible: false,
    toastText: "",
    isPermissionEdit: false,
    timeLineView: false,
    selectedEventId: '',
    timeLineViewEvents: [],
    greetingMessage: "Best Regards, Digital Operations, IKEA Group Digital",
    participiantsOptions: [],
    predictedServices: "",
    changeInformation: "",
    incidentInformation: "",
    ilertTeams: [],
    ilertShifts: [],
    selectedIlertShift: {}
}

const reducer = (state=initialState, action) => {
    switch(action.type) {
        case SET_TOAST: 
            return {
                ...state,
                isToastVisible: true,
                toastText: action.payload
            }
        case RESET_TOAST:
            return {
                ...state,
                isToastVisible: false,
                toastText: ""
            }
        case RESET:
            return {
                ...state,
                isEdit: false,
                selectedEvent: defaultEvent,
                selectedEventId: ''
            }
        case TOGGLE_LOADING:
            //console.log("TOGGLE_LOADING");
            return {
                ...state,
                loading: !state.loading
            }
        case SAVE_EVENT:
            //console.log("SAVE_EVENT");
            return {
                ...state,
                eventList: [...state.eventList, action.payload],
                recentEventList: [...state.recentEventList, action.payload],
            }
        case UPDATE_EVENT:
            //console.log("UPDATE_EVENT");
            return {
                ...state,
                // eventList: [...state.eventList, action.payload],
                // recentEventList: [...state.recentEventList, action.payload],
            }
        case GET_ALL_EVENTS:
            //console.log("GET_ALL_EVENTS");
            return {
                ...state,
                eventList: action.payload,
            }
        case GET_ALL_USER_PERMISSION:
            return {
                ...state,
                userPermissionList: action.payload,
            }
        case GET_RECENT_EVENTS:
            //console.log("GET_RECENT_EVENTS");
            return {
                ...state,
                recentEventList: action.payload,
            }
        case COPY_EVENT: {
            return {
                ...state,
                selectedEvent: action.payload,
                isEdit: false
            }
        }
        case SELECT_EVENT: {
            //console.log("SELECT_EVENT");
            const selected = [...state.recentEventList].find(e => e.id === action.payload)
            return {
                ...state,
                selectedEvent: selected,
                isEdit: true
            }
        }
        case DELETE_EVENT: {
            //console.log("DELETE_EVENT");
            const recentRecentEventList = [...state.recentEventList].filter(e => e.id !== action.payload);
            const recentEventList = [...state.eventList].filter(e => e.id !== action.payload);
            return {
                ...state,
                recentEventList: recentRecentEventList,
                eventList: recentEventList
            }
        }
        case SELECT_USER_PERMISSION: {
            const selected = [...state.userPermissionList].find(e => e.id === action.payload)
            console.log('Selected User Permission', selected, [...state.userPermissionList], action.payload)
            return {
                ...state,
                selectUserPermission: selected,
                isPermissionEdit: true
            }
        }
        case SAVE_PERMISSION :{
            return {
                ...state,
                selectUserPermission: {...action.payload},
                isPermissionEdit: false
                // userPermissionList: [...state.userPermissionList, action.payload],
                // recentUserPermissionList: [...state.recentUserPermissionList, action.payload],
            }
        }
        case UPDATE_PERMISSION:
            //console.log("UPDATE_EVENT");
            return {
                ...state,
                // eventList: [...state.eventList, action.payload],
                // recentEventList: [...state.recentEventList, action.payload],
            }

        case SET_TIMELINE_VIEW:
            return {
                ...state,
                timeLineView: action.payload.state,
                selectedEventId: action.payload.id
            }
        
        case SET_TIMELINE_DATA:
            return {
                ...state,
                timeLineViewEvents: action.payload
            }

        // case DELETE_PERMISSION: {
        //         //console.log("DELETE_EVENT");
        //         const recentRecentEventList = [...state.recentEventList].filter(e => e.id !== action.payload);
        //         const recentEventList = [...state.eventList].filter(e => e.id !== action.payload);
        //         return {
        //             ...state,
        //             recentEventList: recentRecentEventList,
        //             eventList: recentEventList
        //         }
        //     }
        case SET_SERVICE_PREDICTIONS: {
            return {
              ...state,
              predictedServices: action.payload,
            };
          }
        case SET_RECENT_CHANGES: {
            return {
              ...state,
              changeInformation: action.payload,
            };
          }
        case SET_RECENT_INCIDENTS: {
            return {
              ...state,
              incidentInformation: action.payload,
            };
          }
        case GET_ILERT_TEAMS: {
            return {
              ...state,
              ilertTeams: action.payload,
            };
          }
        case GET_ILERT_SHIFTS: {
            return {
              ...state,
              ilertShifts: action.payload,
            };
          }
        case SELECTED_ILERT_SHIFT: {
            return {
              ...state,
              selectedIlertShift: action.payload
            }
          }
        case ADD_PARTICIPANT: {
            console.log(action, state)
            return {
              ...state,
              participiantsOptions: [...state.participiantsOptions, action.payload]
            }
          }
        default:
            return {...state}
    }
}

export default reducer;