import {
  CSH_ITSD_SET_FETCHING_FLAG,
  CSH_ITSD_GET_ALL_EVENTS,
  CSH_ITSD_GET_EVENT,
  CSH_ITSD_GET_EVENT_TIMELINE,
  CSH_ITSD_GET_PINNED_EVENT,
  CSH_ITSD_GET_EVENT_STATUS,
  CSH_ITSD_GET_OL_INFO,
  CSH_ITSD_GET_ALL_ATTACHMENTS,
  CSH_ITSD_SET_TOAST_VISIBLE,
  CSH_ITSD_GET_ITSD_USERS,
  CSH_ITSD_GET_ITSD_ADMINS,
  CSH_ITSD_GET_ITSD_SUPERADMINS,
  CSH_ITSD_GET_PM_RETAIL,
  CSH_ITSD_GET_PM_SUPPLY,
} from "./types";

const initialState = {
  eventsList: [],
  isFetchingEventsList: false,
  selectedEvent: {},
  isFetchingEvent: false,
  selectedEventTimeline: [],
  isFetchingSelectedEventTimeline: false,
  pinnedEvent: [],
  isFetchingPinnedEvent: false,
  eventStatus: [],
  isFetchingEventStatus: false,
  operationalLeadsInfo : [],
  isFetchingOperationalLeadsInfo: false,
  attachments: [],
  isFetchingAttachments: false, 
  toastVisible: false,
  toastText: "",
  itsdUsers: [],
  isFetchingITSDUsers: false,
  itsdAdmins: [],
  isFetchingITSDAdmins: false,
  itsdSuperAdmins: [],
  isFetchingITSDSuperAdmins: false,
};

const reducer = (state = initialState, action) => {
  const type = action.type;

  switch (type) {
    case CSH_ITSD_GET_ALL_EVENTS: {
      return {
        ...state,
        eventsList: action.payload,
      };
    }
    case CSH_ITSD_GET_EVENT: {
      return {
        ...state,
        selectedEvent: action.payload,
      };
    }
    case CSH_ITSD_GET_EVENT_TIMELINE: {
      return {
        ...state,
        selectedEventTimeline: action.payload,
      };
    }
    case CSH_ITSD_GET_PINNED_EVENT: {
      return {
        ...state,
        pinnedEvent: action.payload
      }
    }
    case CSH_ITSD_GET_EVENT_STATUS: {
      return {
        ...state,
        eventStatus: action.payload,
      }
    }
    case CSH_ITSD_GET_OL_INFO: {
      return {
        ...state,
        operationalLeadsInfo: action.payload,
      }
    }
    case CSH_ITSD_GET_ALL_ATTACHMENTS: {
      return {
        ...state,
        attachments : action.payload,
      }
    }
    case CSH_ITSD_SET_TOAST_VISIBLE: {
      return {
        ...state,
        toastVisible: action.payload?.visible,
        toastText: action.payload?.message,
      };
    }
    case CSH_ITSD_GET_ITSD_USERS: {
      return {
        ...state,
        itsdUsers: action.payload,
      };
    }
    case CSH_ITSD_GET_ITSD_ADMINS: {
      return {
        ...state,
        itsdAdmins: action.payload,
      };
    }
    case CSH_ITSD_GET_ITSD_SUPERADMINS: {
      return {
        ...state,
        itsdSuperAdmins: action.payload,
      };
    }
    case CSH_ITSD_GET_PM_RETAIL: {
      return {
        ...state,
        ongoingPMRetail: action.payload,
      };
    }
    case CSH_ITSD_GET_PM_SUPPLY: {
      return {
        ...state,
        ongoingPMSupply: action.payload,
      };
    }
    case CSH_ITSD_SET_FETCHING_FLAG: {
      let apiType = action.payload.apiType;
      let value = action.payload.value;
      switch (apiType) {
        case "getAllEvents": {
          return {
            ...state,
            isFetchingEventsList: value,
          };
        }
        case "getEvent": {
          return {
            ...state,
            isFetchingEvent: value,
          };
        }
        case "getEventTimeline": {
          return {
            ...state,
            isFetchingSelectedEventTimeline: value,
          };
        }
        case "getPinnedEvent" : {
          return {
            ...state,
            isFetchingPinnedEvent: value,
          }
        }
        case "getEventStatus" : {
          return {
            ...state,
            isFetchingEventStatus: value,
          }
        }
        case "getOLInfo" : {
          return {
            ...state,
            isFetchingOperationalLeadsInfo: value,
          }
        }
        case "getAllEventAttachments" : {
          return {
            ...state,
            isFetchingAttachments: value,
          }
        }
        case "getITSDUsers": {
          return {
            ...state,
            isFetchingITSDUsers: value,
          };
        }
        case "getITSDAdmins": {
          return {
            ...state,
            isFetchingITSDAdmins: value,
          };
        }
        case "getITSDSuperAdmins": {
          return {
            ...state,
            isFetchingITSDSuperAdmins: value,
          };
        }
        case "getOngoingPowerMaintenanceRetail": {
          return {
            ...state,
            isFetchingOngoingPMRetail: value,
          };
        }
        case "getOngoingPowerMaintenanceSupply": {
          return {
            ...state,
            isFetchingOngoingPMSupply: value,
          };
        }
        default:
          return { ...state };
      }
    }
    default:
      return { ...state };
  }
};

export default reducer;
