export const CSH_ITSD_SET_FETCHING_FLAG = "CSH_ITSD_SET_FETCHING_FLAG";

export const CSH_ITSD_GET_ALL_EVENTS = "CSH_ITSD_GET_ALL_EVENTS";
export const CSH_ITSD_GET_EVENT = "CSH_ITSD_GET_EVENT";
export const CSH_ITSD_GET_EVENT_TIMELINE = "CSH_ITSD_GET_EVENT_TIMELINE";
export const CSH_ITSD_GET_PINNED_EVENT = "CSH_ITSD_GET_PINNED_EVENT";
export const CSH_ITSD_GET_EVENT_STATUS = "CSH_ITSD_GET_EVENT_STATUS";
export const CSH_ITSD_GET_OL_INFO = "CSH_ITSD_GET_OL_INFO";
export const CSH_ITSD_GET_ALL_ATTACHMENTS = "CSH_ITSD_GET_ALL_ATTACHMENTS";
export const CSH_ITSD_SET_TOAST_VISIBLE = "CSH_ITSD_SET_TOAST_VISIBLE";
export const CSH_ITSD_GET_ITSD_USERS = "CSH_ITSD_GET_ITSD_USERS";
export const CSH_ITSD_GET_ITSD_ADMINS = "CSH_ITSD_GET_ITSD_ADMINS";
export const CSH_ITSD_GET_ITSD_SUPERADMINS = "CSH_ITSD_GET_ITSD_SUPERADMINS";
export const CSH_ITSD_GET_PM_RETAIL = 'CSH_ITSD_GET_PM_RETAIL';
export const CSH_ITSD_GET_PM_SUPPLY = 'CSH_ITSD_GET_PM_SUPPLY';