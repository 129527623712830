import {
  SET_VM_FETCHING_FLAG,
  SET_VM_INSIGHTS_OVERVIEW,
  SET_VM_INSIGHTS_SERVER_LIST,
  SET_VM_INSIGHTS_TIMELINE_DATA,
} from "./types";

const initialState = {
  overview: [],
  serverList: [],
  timelineData: [],

  isFetchingOverview: false,
  isFetchingServerList: false,
  isFetchingTimeline: false,
};

const reducer = (state = initialState, action) => {
  const type = action.type;

  switch (type) {
    case SET_VM_INSIGHTS_OVERVIEW: {
      return {
        ...state,
        overview: action.payload,
      };
    }
    case SET_VM_INSIGHTS_SERVER_LIST: {
      return {
        ...state,
        serverList: action.payload,
      };
    }
    case SET_VM_INSIGHTS_TIMELINE_DATA: {
      return {
        ...state,
        timelineData: action.payload,
      };
    }
    case SET_VM_FETCHING_FLAG: {
      let apiType = action.payload.apiType;
      let value = action.payload.value;
      switch (apiType) {
        case "getOverview": {
          return {
            ...state,
            isFetchingOverview: value,
          };
        }
        case "getServerList": {
          return {
            ...state,
            isFetchingServerList: value,
          };
        }
        case "getTimeline": {
          return {
            ...state,
            isFetchingTimeline: value,
          };
        }
        default:
          return { ...state };
      }
    }
    default:
      return { ...state };
  }
};

export default reducer;
