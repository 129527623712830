export const SET_SM_VIEW_TYPE = "SET_SM_VIEW_TYPE";

export const SET_SM_PLATFORM = "SET_SM_PLATFORM";
export const SET_SM_DB_TYPE = "SET_SM_DB_TYPE";
export const SET_SM_OS_TYPE = "SET_SM_OS_TYPE";
export const SET_SM_SELECTED_CORE_BUSINESS = "SET_SM_SELECTED_CORE_BUSINESS";
export const SET_SM_SELECTED_SOLUTION_AREA = "SET_SM_SELECTED_SOLUTION_AREA";
export const SET_SM_SELECTED_ENV = "SET_SM_SELECTED_ENV";
export const SET_SM_SELECTED_SERVICE = "SET_SM_SELECTED_SERVICE";

export const GET_SM_CORE_BUSINESS_LIST = 'GET_SM_CORE_BUSINESS_LIST';
export const GET_SM_SOLUTION_AREA = 'GET_SM_SOLUTION_AREA';
export const GET_SM_SERVICES_LIST = "GET_SM_SERVICES_LIST";
export const GET_SM_ENV_LIST = "GET_SM_ENV_LIST";

export const GET_SM_SERVERS_LIST = "GET_SM_SERVERS_LIST";
export const GET_SM_SERVERS_LIST_BY_VERSION = "GET_SM_SERVERS_LIST_BY_VERSION";
export const GET_SM_OVERVIEW_METRICS = "GET_SM_OVERVIEW_METRICS";
export const GET_SM_HIERARCHICAL_DATA = "GET_SM_HIERARCHICAL_DATA";
export const GET_SM_TOP20_UNSUPPORTED = "GET_SM_TOP20_UNSUPPORTED";
export const GET_SM_SERVERS_LIST_BY_ENV = "GET_SM_SERVERS_LIST_BY_ENV";
export const GET_SM_PATCH_TIMELINE = "GET_SM_PATCH_TIMELINE";
export const GET_SM_SWARM_DATA = 'GET_SM_SWARM_DATA';
export const SET_SM_SERVICE_BUCKET = 'SET_SM_SERVICE_BUCKET';
export const SET_SM_UNIQUE_SERVICES = 'SET_SM_UNIQUE_SERVICES';

export const SET_SM_FETCHING_FLAG = "SET_SM_FETCHING_FLAG";
export const GET_MAPPING_REQUESTS = "GET_MAPPING_REQUESTS";
export const SET_MAPPING_STATUS = "SET_MAPPING_STATUS";
export const GET_SERVICE_TAG_REQUESTS = "GET_SERVICE_TAG_REQUESTS";
export const SET_SERVICE_MAPPING_HISTORY = "SET_SERVICE_MAPPING_HISTORY";