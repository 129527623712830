
/** CONSTANTS FOR SHIFT API CALL */
export const LOAD_SHIFT_REQUEST = 'LOAD_SHIFT_REQUEST'
export const LOAD_SHIFT_SUCCESS = 'LOAD_SHIFT_SUCCESS'
export const LOAD_SHIFT_FAILED = 'LOAD_SHIFT_FAILED'

/** CONSTANTS FOR UPDATE ASSIGNMENT GROUP */
export const UPDATE_SELECTED_ASSIGNMENT_GROUP = 'UPDATE_SELECTED_ASSIGNMENT_GROUP'

/** CONSTANTS FOR UPDATE PLATFORM */
export const UPDATE_SELECTED_PLATFORM = 'UPDATE_SELECTED_PLATFORM'
export const UPDATE_PRIORITY = 'UPDATE_PRIORITY'

/** CONSTANTS FOR UPDATE SHIFT START TIME & END TIME FROM PAGE 2*/
export const UPDATE_SHIFT_STARTTIME = 'UPDATE_SHIFT_STARTTIME'
export const UPDATE_SHIFT_ENDTIME = 'UPDATE_SHIFT_ENDTIME'

/** CONSTANTS FOR UPDATE SHIFT PRIMARY CONTACT CONFIG */
export const UPDATE_CONTACT = 'UPDATE_CONTACT'
export const UPDATE_CHECK_FIELD_VALUE = 'UPDATE_CHECK_FIELD_VALUE'
export const UPDATE_INTERVAL = 'UPDATE_INTERVAL'

/** CONSTANTS FOR UPDATE NOTIFICATION */
export const UPDATE_WEBHOOK_URL = 'UPDATE_WEBHOOK_URL'
export const UPDATE_CONTACT_NUMBERS = 'UPDATE_CONTACT_NUMBERS'
export const UPDATE_FORM_VALIDATION = 'UPDATE_FORM_VALIDATION'

/** CONSTANTS TO OPEN AND CLOSE VIEW CONFIGURATION */
export const OPEN_CURRENT_CONFIG_WINDOW = 'OPEN_CURRENT_CONFIG_WINDOW'
export const CLOSE_CURRENT_CONFIG_WINDOW = 'CLOSE_CURRENT_CONFIG_WINDOW'

/** CONSTANTS TO OPEN AND CLOSE VIEW REPORT */
export const OPEN_REPORT_WINDOW = 'OPEN_REPORT_WINDOW'
export const CLOSE_REPORT_WINDOW = 'CLOSE_REPORT_WINDOW'

/** CONSTANTS TO DELETE SHIFT FROM CONSOLE FOR IDESK USING API CALL*/
export const DELETE_SHIFT_REQUEST = 'DELETE_SHIFT_REQUEST'
export const DELETE_SHIFT_SUCCESS = 'DELETE_SHIFT_SUCCESS'
export const DELETE_SHIFT_FAILED = 'DELETE_SHIFT_FAILED'

/** CONSTANTS TO CREATE SHIFT FROM CONSOLE FOR IDESK USING API CALL*/
export const CREATE_SHIFT_REQUEST = 'CREATE_SHIFT_REQUEST'
export const CREATE_SHIFT_SUCCESS = 'CREATE_SHIFT_SUCCESS'
export const CREATE_SHIFT_FAILED = 'CREATE_SHIFT_FAILED'

/** CONSTANTS TO CREATE SHIFT FROM CONSOLE FOR IDESK USING API CALL*/
export const UPDATE_SHIFT_REQUEST = 'UPDATE_SHIFT_REQUEST'
export const UPDATE_SHIFT_SUCCESS = 'UPDATE_SHIFT_SUCCESS'
export const UPDATE_SHIFT_FAILED = 'UPDATE_SHIFT_FAILED'

/** CONSTANTS TO UPDATE SHIFT FROM CONSOLE USING IDESK API*/
export const UPDATE_OPERATION_INITIATED = 'UPDATE_OPERATION_INITIATED'
export const UPDATE_OPERATION_COMPLETED = 'UPDATE_OPERATION_COMPLETED'
export const UPDATE_OPERATION_TERMINATED = 'UPDATE_OPERATION_TERMINATED'

/** UPDATE DEFAULT SHIFT STATE OBJECT FOR EACH SUCCESS EXECUTION */
export const UPDATE_SHIFT_OBJECT_WITH_DEFAULT_SCHEMA = 'UPDATE_SHIFT_OBJECT_WITH_DEFAULT_SCHEMA'
export const UPDATE_FORM_SUBMIT_STATUS = 'UPDATE_FORM_SUBMIT_STATUS'

/** SET JIRA PROJECTS */
export const SET_JIRA_PROJECTS = 'SET_JIRA_PROJECTS'

/** SET IDESK ASSIGNMENT GROUPS */
export const SET_IDESK_ASSIGNMENTGROUPS = 'SET_IDESK_ASSIGNMENTGROUPS'

export const SET_SMS_FOR = 'SET_SMS_FOR'
export const SET_WATSAPP_FOR = 'SET_WATSAPP_FOR'
export const SET_TEAMS_FOR = 'SET_TEAMS_FOR'
export const SET_SLACK_FOR = 'SET_SLACK_FOR'