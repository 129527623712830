
import countryData from './response.json'
// import countryData from './countries.json'


const initialState = {
    operatingCountries: [],
    countryList: [],
    siteList: [],
    selectedCountry: '',
    selectedSite: '',
    selectedStorename: '',
    serverListByEnv: [],
    serverListByOS: [],
    servercount: 0,
    AIXservercount: 0,
    linuxservercount: 0,
    windowsservercount: 0,

    serviceList: [],
    hierarchicalData: {},
    swarmChartData: [],
    swarmChartRawData: {},
    serviceTabHostList: [],

    patchauditData: [],
    top20UnsupportedServices: [],
    serverList: [],
    value: '',
    value2: ''
}

const reducer = (state = initialState, action) => {
    const type = action.type;

    switch (type) {
        case 'GET_COUNTRIES': {
            return {
                ...state,
                operatingCountries: action.payload,
                countryList: action.countryList
            };
        }
        case 'GET_SITES': {
            let country = action.payload;
            let contries = [...state.operatingCountries];
            let countryData = contries.find(itr => itr.country === country);
            let tempSiteList = countryData?.siteList ?? [];
            return {
                ...state,
                selectedCountry: country,
                siteList: tempSiteList,
                value: '',
                value2: ''
            };
        }
        case 'SET_SERVER_INFO': {
            return {
                ...state,
                serverListByEnv: action.payload.serverList,
                serverListByOS: action.payload.serverListByOS,
                selectedSite: action.payload.selectedSite,
                selectedStorename: action.payload.selectedStorename,
                servercount: action.payload.servercount,
                AIXservercount: action.payload.AIXservercount,
                linuxservercount: action.payload.linuxservercount,
                windowsservercount: action.payload.windowsservercount,
                value: state.siteList?.find(i => i?.storecode === action.payload.selectedSite),
                value2: state.siteList?.find(i => i?.storecode === action.payload.selectedSite)?.storename
            };
        }

        case 'SET_SERVICES_LIST': {
            return {
                ...state,
                serviceList: action.payload
            }
        }

        case 'SET_HIERARCHICAL_DATA': {
            return {
                ...state,
                hierarchicalData: action.payload
            }
        }

        case 'SET_SWARM_DATA': {
            return {
                ...state,
                swarmChartData: action.payload.formattedData,
                swarmChartRawData: action.payload.rawData,                
            }
        }

        case 'SET_SERVICE_TAB_HOST_LIST': {
            return {
                ...state,
                serviceTabHostList: action.payload
            }
        }

        case 'SET_PATCH_AUDIT_DATA': {
            return {
                ...state,
                patchauditData: action.payload
            }
        }
        
        case 'SET_TOP_20_UNSUPPORTED_SERVICES': {
            return {
                ...state,
                top20UnsupportedServices: action.payload
            }
        }
        case 'SET_SERVER_LIST': {
            return {
                ...state,
                serverList: action.payload
            }
        }
        case "SET_DISTRIBUTED_FETCHING_FLAG": {
            let apiType = action.payload.apiType;
            let value = action.payload.value;
            switch (apiType) {
              case "getCountries": {
                return {
                  ...state,
                  fetchingCountries: value,
                };
              }
              case "getServerInfo": {
                return {
                  ...state,
                  fetchingServerInfo: value,
                };
              }
              case "fetchServicesList": {
                return {
                  ...state,
                  fetchingServiceList: value,
                };
              }
              case "fetchhierarchicalview": {
                return {
                  ...state,
                  fetchingHierarchicalData: value,
                };
              }
              case "fetchServersListBySupportSwarm": {
                return {
                  ...state,
                  fetchingSwarmData: value,
                };
              }
              case "fetchServerBasedOnfilterbyService": {
                return {
                  ...state,
                  fetchingServerForService: value,
                };
              }
              case "fetchTop20Unsupported": {
                return {
                  ...state,
                  fetchingTop20Unsupported: value,
                };
              }
              case "fetchPatchStatus": {
                return {
                  ...state,
                  fetchingPatchStatus: value,
                };
              }
              default:
                return { ...state };
            }
          }

        default:
            return { ...state };
    }
}

export default reducer;