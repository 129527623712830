import { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
// import { useDispatch } from "react-redux";
import Modal, { Prompt, ModalFooter } from "@ingka/modal";

import ikeaLogo from "../../../assets/images/ikea-logo.svg";
import iObserveLogo from "../../../assets/images/iObserve.png";
import Button from "@ingka/button";
import Banner from "@ingka/banner";
import "@ingka/svg-icon/dist/style.css";
import "@ingka/button/dist/style.css";
import "@ingka/focus/dist/style.css";
import "@ingka/banner/dist/style.css";
import "@ingka/modal/dist/style.css";

import account from "@ingka/ssr-icon/paths/person";
import questionMark from "@ingka/ssr-icon/paths/question-mark";
import bellIcon from "@ingka/ssr-icon/paths/bell";
import linkOutIcon from "@ingka/ssr-icon/paths/link-out";

import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";

import styles from "./Navbar.module.css";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { getBanner } from "../../../redux/shiftHandover/actions";

const Navbar = ({ helpIcon, styleClass = "" }) => {
  const { isAuthenticated, loginWithRedirect, logout, user } = useAuth0();
  const [anchorEl, setAnchorEl] = useState(null);
  const { bannerMessage, isFetchingBanner} = useSelector(
    (state) => state.shiftHandover
  )

  const history = useHistory();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      {process.env.REACT_APP_ENV === "DEV" && (
        <Banner
          body={
            <p>
              This is a staging environment, data here might not be up to date.
              Please{" "}
              <a
                className={styles.url}
                href={`https://iobserve.oi.ingka.com${history.location.pathname}`}
              >
                click here
              </a>{" "}
              for the production instance.{" "}
            </p>
          }
          emergency
          dismissable
        />
      )}
      
      {history.location.pathname.includes("/shift-handover") && <>
        { bannerMessage?.bannertext && (
          <Banner
            body={ isFetchingBanner ? <p>...</p> :
              bannerMessage?.bannertext?.split("\n").map((text, id) =>
                <p key={id}>
                  {text}
                </p>
              )
            }
            emergency
            dismissable
          />
        )}
      </>
      }
      {history.location.pathname === "/self-service" &&
        process.env.REACT_APP_ENV === "PROD" && (
          <Banner
            body={
              <p>
                For Service mapping related actions, please visit{" "}
                <a
                  className={styles.url}
                  href={`https://iobserve.oi.ingka.com/osvisualization/servicemapping`}
                >
                  this page
                </a>
              </p>
            }
            emergency
            dismissable
          />
        )}
      <div className={`${styles.container} ${styleClass}`}>
        {/* <img src={menuIcon} alt='menu icon' /> */}
        <img src={ikeaLogo} alt="IKEA logo" className={styles.logo} />
        <img
          src={iObserveLogo}
          alt="iObserve logo"
          className={styles.logoiO}
          onClick={() => {
            history.push("/");
          }}
        />
        {/* <img src={userIcon} alt='user icon' className={styles.userIcon} /> */}

        {!isAuthenticated && (
          <Button
            style={{ marginLeft: "auto" }}
            onClick={() => loginWithRedirect({})}
            className="btn--primary"
            type="button"
            inverseTheme
            small
          >
            Login
          </Button>
        )}
        {isAuthenticated && (
          <div className={styles.user}>
            {helpIcon === true && (
              <Button
                type="primary"
                small
                onClick={() => {
                  history.push("/self-service");
                }}
                text="Support centre"
              />
            )}
            
            <Button
              type="primary"
              small
              iconOnly
              ssrIcon={account}
              onClick={handleClick}
              style={{ marginLeft: "20px" }}
            />
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
              style={{ marginTop: "30px" }}
            >
              <MenuItem
                onClick={() => {
                  logout({ returnTo: window.location.origin });
                }}
              >
                Logout
              </MenuItem>
            </Menu>
          </div>
        )}
      </div>
    </>
  );
};

Navbar.defaultProps = {
  helpIcon: true,
};

export default Navbar;