export const GET_MIDDLEWARE_FILTERS_LIST = "GET_MIDDLEWARE_FILTERS_LIST";
export const GET_MIDDLEWARE_ASSETS_SERVER_LIST = "GET_MIDDLEWARE_ASSETS_SERVER_LIST";
export const GET_MIDDLEWARE_ASSETS_ENV_STATS = "GET_MIDDLEWARE_ASSETS_ENV_STATS";
export const GET_MIDDLEWARE_ASSETS_STATS_PLATFORM_VS_TECH = "GET_MIDDLEWARE_ASSETS_STATS_PLATFORM_VS_TECH";
export const GET_MIDDLEWARE_ASSETS_MONTHLY_TRENDS = "GET_MIDDLEWARE_ASSETS_MONTHLY_TRENDS";
export const GET_MIDDLEWARE_ASSETS_STATS_GEO_VS_DC = "GET_MIDDLEWARE_ASSETS_STATS_GEO_VS_DC";
export const GET_MIDDLEWARE_ASSETS_STATS_HW_MODELS = "GET_MIDDLEWARE_ASSETS_STATS_HW_MODELS";
export const GET_MIDDLEWARE_STORAGE_SERVER_LIST="GET_MIDDLEWARE_STORAGE_SERVER_LIST";
export const GET_MIDDLEWARE_STORAGE_MONTHLY="GET_MIDDLEWARE_STORAGE_MONTHLY";
export const GET_MIDDLEWARE_ARTIFACT_LIST = "GET_MIDDLEWARE_ARTIFACT_LIST";
export const GET_MIDDLEWARE_ARTIFACT_TOTAL_LIST = "GET_MIDDLEWARE_ARTIFACT_TOTAL_LIST";



export const SET_MIDDLEWARE_FETCHING_FLAG = "SET_MIDDLEWARE_FETCHING_FLAG";
export const SET_MIDDLEWARE_VIEW_TYPE = "SET_MIDDLEWARE_VIEW_TYPE";
export const SET_MIDDLEWARE_ORGANIZATION = "SET_MIDDLEWARE_ORGANIZATION";
export const SET_MIDDLEWARE_FILTERS ="SET_MIDDLEWARE_FILTERS";
export const RESET_MIDDLEWARE_FILTERS = "RESET_MIDDLEWARE_FILTERS";