import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import TagManager from "react-gtm-module";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";

const useGaTracker = () => {
  const location = useLocation();
  const [initialized, setInitialized] = useState(false);
  const [userID, setUserID] = useState("");

  const { getAccessTokenSilently, user, isAuthenticated } = useAuth0();

  useEffect(() => {
    if (isAuthenticated) {
      const getUID = async () => {
        const url = `https://iobserve-backend-soyaaeguoa-ez.a.run.app/aiops/iobserve/ga`;
        const accessToken = await getAccessTokenSilently();
        try {
          const response = await axios.get(
            `${url}/getUserDetails?email=${user?.email}`,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${accessToken}`,
              },
            }
          );
          const UID = response?.data?.userID;
          setUserID(UID);
          return UID;
        } catch (error) {
          console.error(error);
        }
      };
      getUID();
    }
  }, [user?.email, isAuthenticated, getAccessTokenSilently]);

  useEffect(() => {
    if (userID !== "") {
      const tagManagerArgs = {
        gtmId: "GTM-5DGW8TL",
        dataLayer: {
          userId: userID,
        },
      };
      TagManager.initialize(tagManagerArgs);
      setInitialized(true);
    }
  }, [userID]);

  useEffect(() => {
    if (initialized) {
      setTimeout(() => {
        TagManager.dataLayer({
          dataLayer: {
            event: "pageview",
            pagePath: location.pathname,
            userId: userID,
            selectedElement: "",
          },
        });
      }, 10000);
      // console.log("current location", location.pathname, title);
    }
  }, [initialized, location, userID]);
};

export const selectionMadeEvent = (selectedOption) => {
  // console.log(selectedOption);
  TagManager.dataLayer({
    dataLayer: {
      event: "selectionMade",
      selectedElement: selectedOption,
    },
  });
};

export const serviceMappingEvent = (server, from, to) => {
  // console.log(server, from, to);
  TagManager.dataLayer({
    dataLayer: {
      event: "serviceMappingEvent",
      server: server,
      from: from,
      to: to,
    },
  });
};

export default useGaTracker;
