export const REQUEST_USERS_AND_ROLES = 'REQUEST_USERS_AND_ROLES';
export const FETCH_USERS_AND_ROLE_SUCCESS = 'FETCH_USERS_AND_ROLE_SUCCESS';
export const FETCH_USERS_AND_ROLE_FAILED = 'FETCH_USERS_AND_ROLE_FAILED';

export const REQUEST_ADD_USERS_AND_ROLES = 'REQUEST_ADD_USERS_AND_ROLES';
export const ADD_USERS_AND_ROLES_SUCCESS = 'ADD_USERS_AND_ROLES_SUCCESS';
export const ADD_USERS_AND_ROLES_FAILED = 'ADD_USERS_AND_ROLES_FAILED';

export const REQUEST_UPDATE_USERS_AND_ROLES = 'REQUEST_UPDATE_USERS_AND_ROLES';
export const UPDATE_USERS_AND_ROLES_SUCCESS = 'UPDATE_USERS_AND_ROLES_SUCCESS';
export const UPDATE_USERS_AND_ROLES_FAILED = 'UPDATE_USERS_AND_ROLES_FAILED';