import { SET_APIM_TOKEN, SET_TEAMS, SET_MLOPS_FETCHING_FLAG } from "./types";

const defaultState = {
  teamList: [],
  apimToken: ""
};

const reducer = (state = defaultState, action) => {
  switch (action.type) {
    case SET_APIM_TOKEN: {
      return {
        ...state,
        apimToken: action.payload
      }
    }
    case SET_TEAMS: {
      return {
        ...state,
        teamList: action.payload
      }
    }
    case "ADD_TEAM": {
      const tempTeams = [
        ...state.teamList,
        action.payload
      ];
      return {
        ...state,
        teamList: tempTeams,
      };
    }
    case "UPDATE_TEAM": {
      const tempTeams = [...state.teamList];
      tempTeams?.map(team => team?.teamname === action?.payload?.teamname ? action?.payload : team );
      return {
        ...state,
        teamList: tempTeams,
      };
    }
    case "ADD_MEMBER": {
      const teamname = action?.payload?.teamname;
      const tempTeams = [...state.teamList];
      const selectedTeam = tempTeams?.filter(
        (itr) => itr?.teamname === teamname
      );
      selectedTeam["teamdetails"] = [
        ...selectedTeam["teamdetails"],
        { id: Math.random(), name: "", sshKey: "", is_leader: false },
      ];
      return {
        ...state,
        teamList: tempTeams,
      };
    }
    case "HANDLE_CHANGE": {
      const teamname = action?.payload?.teamname;
      const tempTeams = [...state.teamList];
      const selectedTeam = tempTeams?.filter(
        (itr) => itr?.teamname === teamname
      );

      const rowId = action?.payload?.rowId;
      const fieldName = action?.payload?.fieldName;
      const fieldValue = action?.payload?.fieldValue;

      let tempTeamMembers = [...tempTeamMembers];
      tempTeamMembers[rowId][fieldName] = fieldValue;

      selectedTeam["teamdetails"] = tempTeamMembers;
      return {
        ...state,
        teamList: tempTeams,
      };
    }
    case SET_MLOPS_FETCHING_FLAG: {
      let apiType = action.payload.apiType;
      let value = action.payload.value;
      switch (apiType) {
        case "getTeams": {
          return {
            ...state,
            isFetchingTeams: value,
          }
        }
        default:
          return { ...state };
      }
    }
    default:
      return { ...state };
  }
};

export default reducer;
