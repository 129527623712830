import React, {Fragment} from 'react';
import { useHistory } from 'react-router-dom';
import {Grid} from '@material-ui/core';

import Navbar from "../../components/app/Navbar/Navbar";

import Button from "@ingka/button";

import "@ingka/pill/dist/style.css";
import "@ingka/svg-icon/dist/style.css";
import "@ingka/button/dist/style.css";

const UnAuthorizedPage = () => {

    const history = useHistory();

    return (
        <>
            <Navbar />
            <Grid container justifyContent="center">
                <Grid item container justifyContent="center" sm={12}>
                    <h2>401 - Unauthorized</h2>
                </Grid>
                <Grid item container justifyContent="center" sm={12}>
                    <h6>You don't have permission to view this page</h6>
                </Grid>
                <Grid item container justifyContent="center" sm={12}>
                    <Button 
                        text={"Home"} 
                        type="primary" 
                        onClick={() => { history.push("/") }}
                        small
                        />
                </Grid>
            </Grid>
        </>
    )
}

export default UnAuthorizedPage;