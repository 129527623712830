export const SET_DB_VIEW_TYPE = "SET_DB_VIEW_TYPE";

export const SET_DB_PLATFORM = "SET_DB_PLATFORM";
export const SET_DB_DB_TYPE = "SET_DB_DB_TYPE";
export const SET_DB_OS_TYPE = "SET_DB_OS_TYPE";
export const SET_DB_SELECTED_CORE_BUSINESS = "SET_DB_SELECTED_CORE_BUSINESS";
export const SET_DB_SELECTED_SOLUTION_AREA = "SET_DB_SELECTED_SOLUTION_AREA";
export const SET_DB_SELECTED_ENV = "SET_DB_SELECTED_ENV";
export const SET_DB_SELECTED_SERVICE = "SET_DB_SELECTED_SERVICE";

export const GET_DB_CORE_BUSINESS_LIST = 'GET_DB_CORE_BUSINESS_LIST';
export const GET_DB_SOLUTION_AREA = 'GET_DB_SOLUTION_AREA';
export const GET_DB_SERVICES_LIST = "GET_DB_SERVICES_LIST";
export const GET_DB_ENV_LIST = "GET_DB_ENV_LIST";

export const GET_DB_SERVERS_LIST = "GET_DB_SERVERS_LIST";
export const GET_DB_SERVERS_LIST_BY_VERSION = "GET_DB_SERVERS_LIST_BY_VERSION";
export const GET_DB_OVERVIEW_METRICS = "GET_DB_OVERVIEW_METRICS";
export const GET_DB_HIERARCHICAL_DATA = "GET_DB_HIERARCHICAL_DATA";
export const GET_DB_TOP20_UNSUPPORTED = "GET_DB_TOP20_UNSUPPORTED";
export const GET_DB_SERVERS_LIST_BY_ENV = "GET_DB_SERVERS_LIST_BY_ENV";
export const GET_DB_PATCH_TIMELINE = "GET_DB_PATCH_TIMELINE";
export const GET_DB_SWARM_DATA = 'GET_DB_SWARM_DATA';

export const SET_DB_FETCHING_FLAG = "SET_DB_FETCHING_FLAG";
export const SET_OS_EOS_DATES_LIST = "SET_OS_EOS_DATES_LIST";
export const SET_DB_EOS_DATES_LIST = "SET_DB_EOS_DATES_LIST";

