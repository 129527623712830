import {
  SET_PLATFORM,
  SET_OS_TYPE,
  SET_SELECTED_CORE_BUSINESS,
  SET_SELECTED_SOLUTION_AREA,
  GET_CORE_BUSINESS_LIST,
  GET_SOLUTION_AREA,
  SET_SELECTED_SERVICE,
  GET_SERVICES_LIST,
  SET_SELECTED_ENV,
  GET_ENV_LIST,
  GET_SERVERS_LIST,
  GET_SERVERS_LIST_BY_VERSION,
  GET_SERVERS_LIST_BY_SERVICE,
  GET_OVERVIEW_METRICS,
  GET_HIERARCHICAL_DATA,
  GET_TOP20_UNSUPPORTED,
  GET_SERVERS_LIST_BY_ENV,
  GET_PATCH_TIMELINE,
  GET_SWARM_DATA,
  GET_TREND_DATA,
  SET_FETCHING_FLAG,
  GET_FINOPS_DATA,
  SET_SELECTED_DOMAIN,
  SET_SELECTED_SUB_DOMAIN,
  GET_SUB_DOMAIN,
  GET_DOMAIN
} from "./types";

const initialState = {
  viewType: "osvisualization",
  selectedPlatform: 0,
  selectedOsType: "Linux",
  selectedCoreBusiness: "All",
  selectedSolutionArea: "All",
  selectedEnv: "All",
  selectedService: "All",
  selectedDomain: "All",
  selectedSubDomain: "All",
  coreBusinessList: [],
  isFetchingCoreBusinessList: false,
  solutionAreaList: [],
  isFetchingSolutionArea: false,
  envList: [],
  isFetchingEnvList: false,
  servicesList: [],
  isFetchingServicesList: false,
  serversList: [],
  isFetchingServersList: false,
  serversListByVersion: [],
  isFetchingServersListByVersion: false,
  serversListByService: [],
  isFetchingServersListByService: false,
  overviewMetrics: {},
  isFetchingOverviewMetrics: false,
  hierarchicalData: {},
  isFetchingHierarchicalData: false,
  top20Unsupported: [],
  isFetchingTop20Unsupported: false,
  serversListByEnv: [],
  isFetchingServersListByEnv: false,
  patchTimeline: {},
  isFetchingPatchTimeline: false,
  swarmData: {},
  isFetchingSwarmData: false,
  trendData: [],
  isFetchingTrendData: false,
  isFetchingFinopsData: false,
  isFetchingDomainList: false,
  isFetchingSubDomainList: false,
  domainList: [],
  subDomainList: [],
};

const reducer = (state = initialState, action) => {
  const type = action.type;

  switch (type) {
    case SET_PLATFORM: {
      return {
        ...state,
        selectedPlatform: action.payload,
      };
    }
    case SET_OS_TYPE: {
      return {
        ...state,
        selectedOsType: action.payload,
      };
    }
    case SET_SELECTED_CORE_BUSINESS: {
      return {
        ...state,
        selectedCoreBusiness: action.payload,
      };
    }
    case GET_CORE_BUSINESS_LIST: {
      return {
        ...state,
        coreBusinessList: action.payload,
      };
    }
    case SET_SELECTED_SOLUTION_AREA: {
      return {
        ...state,
        selectedSolutionArea: action.payload,
      };
    }
    case GET_SOLUTION_AREA: {
      return {
        ...state,
        solutionAreaList: action.payload,
      };
    }

    case SET_SELECTED_SERVICE: {
      return {
        ...state,
        selectedService: action.payload,
      };
    }
    case GET_SERVICES_LIST: {
      return {
        ...state,
        servicesList: action.payload,
      };
    }
    case SET_SELECTED_ENV: {
      return {
        ...state,
        selectedEnv: action.payload,
      };
    }
    case GET_ENV_LIST: {
      return {
        ...state,
        envList: action.payload,
      };
    }
    case GET_SERVERS_LIST: {
      return {
        ...state,
        serversList: action.payload,
      };
    }
    case GET_SERVERS_LIST_BY_VERSION: {
      return {
        ...state,
        serversListByVersion: action.payload,
      };
    }
    case GET_SERVERS_LIST_BY_SERVICE: {
      return {
        ...state,
        serversListByService: action.payload,
      };
    }
    case GET_OVERVIEW_METRICS: {
      return {
        ...state,
        overviewMetrics: action.payload,
      };
    }
    case GET_HIERARCHICAL_DATA: {
      return {
        ...state,
        hierarchicalData: action.payload,
      };
    }
    case GET_TOP20_UNSUPPORTED: {
      return {
        ...state,
        top20Unsupported: action.payload,
      };
    }
    case GET_SERVERS_LIST_BY_ENV: {
      return {
        ...state,
        serversListByEnv: action.payload,
      };
    }
    case GET_PATCH_TIMELINE: {
      return {
        ...state,
        patchTimeline: action.payload,
      };
    }
    case GET_SWARM_DATA: {
      return {
        ...state,
        swarmData: action.payload,
      };
    }
    case GET_TREND_DATA: {
      return {
        ...state,
        trendData: action.payload,
      }
    }
    case GET_FINOPS_DATA: {
      return {
        ...state,
        finopsData: action.payload,
      }
    }
    case SET_SELECTED_DOMAIN: {
      return {
        ...state,
        selectedDomain: action.payload,
      };
    }
    case SET_SELECTED_SUB_DOMAIN: {
      return {
        ...state,
        selectedSubDomain: action.payload,
      };
    }
    case GET_SUB_DOMAIN: {
      return {
        ...state,
        subDomainList: action.payload,
      };
    }
    case GET_DOMAIN: {
        return {
          ...state,
          domainList: action.payload,
        }; 
    }
    
    case SET_FETCHING_FLAG: {
      let apiType = action.payload.apiType;
      let value = action.payload.value;
      switch (apiType) {
        case "getCoreBusinessList": {
          return {
            ...state,
            isFetchingCoreBusinessList: value,
          };
        }
        case "getSolutionAreaList": {
          return {
            ...state,
            isFetchingSolutionArea: value,
          };
        }
        case "getServicesList": {
          return {
            ...state,
            isFetchingServicesList: value,
          };
        }
        case "getEnvList": {
          return {
            ...state,
            isFetchingEnvList: value,
          };
        }
        case "getServersList": {
          return {
            ...state,
            isFetchingServersList: value,
          };
        }
        case "getServersListByVersion": {
          return {
            ...state,
            isFetchingServersListByVersion: value,
          };
        }
        case "getServersListByService": {
          return {
            ...state,
            isFetchingServersListByService: value,
          };
        }
        case "getOverviewMetrics": {
          return {
            ...state,
            isFetchingOverviewMetrics: value,
          };
        }
        case "getHierarchicalData": {
          return {
            ...state,
            isFetchingHierarchicalData: value,
          };
        }
        case "getTop20Unsupported": {
          return {
            ...state,
            isFetchingTop20Unsupported: value,
          };
        }
        case "getServersListByEnv": {
          return {
            ...state,
            isFetchingServersListByEnv: value,
          };
        }
        case "getPatchTimeline": {
          return {
            ...state,
            isFetchingPatchTimeline: value,
          };
        }
        case "getSwarmData": {
          return {
            ...state,
            isFetchingSwarmData: value,
          };
        }
        case "getTrendData": {
          return {
            ...state,
            isFetchingTrendData: value,
          }
        }
        case "getFinopsData": {
          return {
            ...state,
            isFetchingFinopsData: value,
          }
        }
        case "getDomainList": {
          return {
            ...state,
            isFetchingDomainList: value,
          };
        }
        case "getSubDomainList": {
          return {
            ...state,
            isFetchingSubDomainList: value,
          };
        }
        default:
          return { ...state };
      }
    }
    default:
      return { ...state };
  }
};

export default reducer;
