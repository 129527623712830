import { combineReducers } from "redux";

/** Reducers */
import authenticationReducer from "./authentication/reducers";
import authorizationReducer from "./authorization/reducers";
import developerConnectReducer from "./developerconnectportal/android/reducers";
import iosDeveloperConnectReducer from "./developerconnectportal/ios/reducers";
import userReducer from "./user/reducers";
import developerConnectFeedbackReducer from "./developerfeedbackprocess/reducers";
import dcpSettingsReducer from "./settings/reducers";
import acsSelfServicePortal from "./acsselfserviceportal/reducers";
import shiftmanagementreducer from "./shiftmanagement/reducers";
import shiftmanagementrbacreducer from "./shiftmanagementrbac/reducers";
import notifierreducer from "./notification/reducers";
import csmreducer from "./csm/reducers";
import occreducer from "./occ/reducers";
import gitreducer from "./gitvisualization/reducers";
import managementviewreducer from "./managementview/reducers";
import distributedreducer from "./osvisualization/managementview/distributed/reducers";
import interosreducer from "./osvisualization/managementview/interos/reducers";
import interosaixreducers from "./osvisualization/managementview/interos/aixreducers";
import interoswindowsreducers from "./osvisualization/managementview/interos/windowsreducers";
import securityviewreducer from "./securityview/reducers";
import changeautomationreducers from "./changeautomation/reducers";
import dbmanagementviewreducers from "./dbmanagementview/reducers";
import engineeringviewreducers from "./osvisualization/engineeringview/reducers";
import servicemappingreducers from "./servicemapping/reducers";
import situationroomreducers from "./situationroom/reducers";
import mlopsreducers from "./mlops/reducers";
import newmlopsreducers from "./new-mlops/reducers";
import vmreducers from "./vminsights/reducers";
import storagevizreducers from "./storageviz/reducers"
import scheduleviewreducers from "./osvisualization/scheduleview/reducers"
import situationroomitsd from "./situationroomitsd/reducers"
import middleWareReducers from "./middleware/reducers"
import shiftHandoverReducers from "./shiftHandover/reducers"

const reducers = combineReducers({
  au: authenticationReducer,
  android: developerConnectReducer,
  iosdcp: iosDeveloperConnectReducer,
  at: authorizationReducer,
  usr: userReducer,
  dcpfp: developerConnectFeedbackReducer,
  dcpsettings: dcpSettingsReducer,
  acsssp: acsSelfServicePortal,
  shmgmt: shiftmanagementreducer,
  shrbac: shiftmanagementrbacreducer,
  notifier: notifierreducer,
  csmreducer: csmreducer,
  occ: occreducer,
  gitvisualization: gitreducer,
  distributedreducer: distributedreducer,
  interosreducer: interosreducer,
  interosaixreducers: interosaixreducers,
  interoswindowsreducers: interoswindowsreducers,
  managementview: managementviewreducer,
  securityview: securityviewreducer,
  changemanagement: changeautomationreducers,
  dbmanagementview: dbmanagementviewreducers,
  engineeringview: engineeringviewreducers,
  servicemappingreducers: servicemappingreducers,
  situationroom: situationroomreducers,
  mlops: mlopsreducers,
  newMLOps: newmlopsreducers,
  vminsights: vmreducers,
  storageviz: storagevizreducers,
  scheduleview: scheduleviewreducers,
  situationroomitsd: situationroomitsd,
  middlewareView: middleWareReducers,
  shiftHandover: shiftHandoverReducers,
});

export default reducers;
