import React from "react";
import { Route } from "react-router-dom";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import LoadingWidget from "../app/Loading/LoadingWidget";

import { connect } from "react-redux";

import withCDSAuth from "./CDSAuthHOC";
import APIMAuthHOC from "./APIMAuthHOC";

const ProtectedRoute = ({ component, path, ...args }) => {
  const paths = [
    "/git-visualization",
    "/CSH",
    "/CSH/:roomId",
    "/CSH-Datafication",
    "/CSH-Datafication/:roomId",
    "/CSH-insights",
    "/CSH-ITSD",
    "/CSH-ITSD/:id",
    "/CSH-ITSD/user-management"
  ];
  const getComponent = () => {
    if (path.includes("OCC-dashboard")) {
      return withCDSAuth(component);
    } else if (paths.includes(path)) {
      return APIMAuthHOC(component);
    }
    return component;
  };

  return (
    <Route
      component={withAuthenticationRequired(getComponent(), {
        onRedirecting: () => <LoadingWidget />,
      })}
      {...args}
    />
  );
};

export default connect(null, null)(ProtectedRoute);
