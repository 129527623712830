import { AUTHENTICATE_FAILED, AUTHENTICATE_REQUEST, AUTHENTICATE_SUCCESS, LOGOUT_FAILED, LOGOUT_REQUEST, LOGOUT_SUCCESS } from './types';

const initialState = {
    loading: false,
    authenticated: false,
    token: '',
    scope: '',
    token_type: '',
    error: false,
    errorMessage: ''
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case AUTHENTICATE_REQUEST:
            return {
                ...state,
                loading: true,
                token: '',
                scope: '',
                token_type: '',
                error: false,
                errorMessage: ''
            }
        case AUTHENTICATE_SUCCESS:
            return {
                ...state,
                loading:false,
                authenticated: true,
                token: action.payload.token
                // token: action.payload.access_token,
                // scope: action.payload.scope,
                // token_type: action.payload.token_type
            }
        case AUTHENTICATE_FAILED:
            return {
                ...state,
                loading: false,
                authenticated: false,
                token: '',
                scope: '',
                token_type: '',
                error: true,
                errorMessage: action.payload
            }
        case LOGOUT_REQUEST:
            return {
                ...state,
                loading: true,
                error: false,
                errorMessage: ''
            }
        case LOGOUT_SUCCESS:
            return {
                ...state,
                loading: false,
                authenticated: false,
                token: '',
                scope: '',
                token_type: '',
                error: false,
                errorMessage: ''
            }
        case LOGOUT_FAILED:
            return {
                ...state,
                loading: false,
                authenticated: false,
                token: '',
                scope: '',
                token_type: '',
                error: true,
                errorMessage: action.payload
            }
        default:
            return state;
    }
}

export default reducer;