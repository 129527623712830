import moment from "moment";

import {
  GET_CURRENT_SCHEDULES,
  GET_PAST_SCHEDULES,
  APPROVE_SCHEDULES,
  DENY_SCHEDULES,
  GET_APPROVED_SCHEDULES,
  PROCESS_SCHEDULES,
  GET_PROCESSED_SCHEDULES,
  GET_UNION_REPORT,
  SET_FETCHING,
  GET_DISPUTED_SCHEDULES,
  GET_DENIED_SCHEDULES,
  GET_ILERT_SCHEDULES,
  SET_FETCHING_FLAG,
  GET_SCHEDULED_TEAMS,
  GET_PAST_ENTRIES,
  ADD_ENTRY,
  OCC_SET_TOAST_VISIBLE,
  SET_OCC_FILTER_PERIOD,
  SET_OCC_CA_FILTER_PERIOD,
  SET_OCC_ILERT_FILTER_PERIOD,
  SET_OCC_PA_FILTER_PERIOD,
  SET_OCC_DISPUTED_FILTER_PERIOD,
  SET_OCC_DENIED_FILTER_PERIOD,
} from "./types";

const initialState = {
  filterDuration: 30,
  caFilterDuration: {
    from: moment().subtract(30, "days").format("YYYY-MM-DD"),
    to: moment().format("YYYY-MM-DD")
  },
  ilertFilterDuration: {
    from: moment().subtract(30, "days").format("YYYY-MM-DD"),
    to: moment().format("YYYY-MM-DD")
  },
  paFilterDuration: {
    from: moment().subtract(30, "days").format("YYYY-MM-DD"),
    to: moment().format("YYYY-MM-DD")
  },
  disputeFilterDuration: {
    from: moment().subtract(30, "days").format("YYYY-MM-DD"),
    to: moment().format("YYYY-MM-DD")
  },
  deniedFilterDuration: {
    from: moment().subtract(30, "days").format("YYYY-MM-DD"),
    to: moment().format("YYYY-MM-DD")
  },
  currentSchedules: [],
  pastSchedules: [],
  approvedSchedules: [],
  processedSchedules: [],
  disputedSchedules: [],
  deniedSchedules: [],
  unionReport: [],
  iLertSchedules: [],
  isFetching: false,
  isFetchingIlertSchedules: false,
  isFetchingCurrentApprovals: false,
  isFetchingPastApprovals: false,
  isFetchingDisputedSchedules: false,
  isFetchingDeniedSchedules: false,
  isFetchingApprovedSchedules: false,
  isFetchingProcessedSchedules: false,
  isFetchingUnionReport: false,
  scheduledTeams: [],
  isFetchingScheduledTeams: false,
  pastEntries: [],
  isFetchingPastEntries: false,
  toastVisible: false,
  toastText: "",
};

const removeServiceAccounts = (schedules) => {
  return schedules.filter((schedule) =>
    schedule.designation && schedule.designation.toLowerCase() === "employee"
      ? false
      : true
  );
};

const reducer = (state = initialState, action) => {
  const type = action.type;

  switch (type) {
    case SET_OCC_FILTER_PERIOD: {
      return {
        ...state,
        filterDuration: action.payload
      };
    }
    case SET_OCC_CA_FILTER_PERIOD: {
      return {
        ...state,
        caFilterDuration: action.payload
      };
    }
    case SET_OCC_ILERT_FILTER_PERIOD: {
      return {
        ...state,
        ilertFilterDuration: action.payload
      };
    }
    case SET_OCC_PA_FILTER_PERIOD: {
      return {
        ...state,
        paFilterDuration: action.payload
      };
    }
    case SET_OCC_DISPUTED_FILTER_PERIOD: {
      return {
        ...state,
        disputeFilterDuration: action.payload
      };
    }
    case SET_OCC_DENIED_FILTER_PERIOD: {
      return {
        ...state,
        deniedFilterDuration: action.payload
      };
    }
    case APPROVE_SCHEDULES: {
      // let ids = action.payload.map((s) => s.id);
      // let updatedCurrentSchedules = [...state.currentSchedules].filter(
      //   ({ id }) => {
      //     return !ids.includes(id);
      //   }
      // );
      return {
        ...state,
        // currentSchedules: updatedCurrentSchedules,
        // pastSchedules: [...state.pastSchedules].concat(action.payload),
      };
    }
    case DENY_SCHEDULES: {
      return {
        ...state,
      };
    }
    case PROCESS_SCHEDULES: {
      // let ids = action.payload.map((s) => s.id);
      // let updatedApprovedSchedules = [...state.approvedSchedules].filter(
      //   ({ id }) => {
      //     return !ids.includes(id);
      //   }
      // );
      return {
        ...state,
        // approvedSchedules: updatedApprovedSchedules,
        // processedSchedules: [...state.processedSchedules].concat(
        //   action.payload
        // ),
      };
    }
    case GET_ILERT_SCHEDULES: {
      const tempSchedules = removeServiceAccounts(action.payload || []);
      return {
        ...state,
        iLertSchedules: tempSchedules,
      };
    }
    case GET_CURRENT_SCHEDULES: {
      const tempSchedules = removeServiceAccounts(action.payload || []);
      return {
        ...state,
        currentSchedules: tempSchedules,
      };
    }
    case GET_PAST_SCHEDULES: {
      const tempSchedules = removeServiceAccounts(action.payload || []);
      return {
        ...state,
        pastSchedules: tempSchedules,
      };
    }
    case GET_DISPUTED_SCHEDULES: {
      const tempSchedules = removeServiceAccounts(action.payload || []);
      return {
        ...state,
        disputedSchedules: tempSchedules,
      };
    }
    case GET_DENIED_SCHEDULES: {
      const tempSchedules = removeServiceAccounts(action.payload || []);
      return {
        ...state,
        deniedSchedules: tempSchedules,
      };
    }
    case GET_APPROVED_SCHEDULES: {
      const tempSchedules = removeServiceAccounts(action.payload || []);
      return {
        ...state,
        approvedSchedules: tempSchedules,
      };
    }
    case GET_PROCESSED_SCHEDULES: {
      const tempSchedules = removeServiceAccounts(action.payload || []);
      return {
        ...state,
        processedSchedules: tempSchedules,
      };
    }
    case GET_UNION_REPORT: {
      const tempSchedules = removeServiceAccounts(
        (action.payload || []).filter(
          (report) => report.approvalstatus !== "indispute"
        )
      );
      return {
        ...state,
        unionReport: tempSchedules,
      };
    }
    case GET_SCHEDULED_TEAMS: {
      return {
        ...state,
        scheduledTeams: action.payload,
      };
    }
    case GET_PAST_ENTRIES: {
      return {
        ...state,
        pastEntries: action.payload,
      };
    }
    case ADD_ENTRY: {
      return {
        ...state,
      };
    }
    case SET_FETCHING: {
      return {
        ...state,
        isFetching: action.payload,
      };
    }
    case OCC_SET_TOAST_VISIBLE: {
      return {
        ...state,
        toastVisible: action.payload?.visible,
        toastText: action.payload?.message,
      }
    }
    case SET_FETCHING_FLAG: {
      let apiType = action.payload.apiType;
      let value = action.payload.value;
      switch (apiType) {
        case "getiLertSchedules": {
          return {
            ...state,
            isFetchingIlertSchedules: value,
          };
        }
        case "getCurrentSchedules": {
          return {
            ...state,
            isFetchingCurrentApprovals: value,
          };
        }
        case "getPastSchedules": {
          return {
            ...state,
            isFetchingPastApprovals: value,
          };
        }
        case "getDisputedSchedules": {
          return {
            ...state,
            isFetchingDisputedSchedules: value,
          };
        }
        case "getDeniedSchedules": {
          return {
            ...state,
            isFetchingDeniedSchedules: value,
          };
        }
        case "getApprovedSchedules": {
          return {
            ...state,
            isFetchingApprovedSchedules: value,
          };
        }
        case "getProcessedSchedules": {
          return {
            ...state,
            isFetchingProcessedSchedules: value,
          };
        }
        case "getUnionReport": {
          return {
            ...state,
            isFetchingUnionReport: value,
          };
        }
        case "getScheduledTeams": {
          return {
            ...state,
            isFetchingScheduledTeams: value,
          };
        }
        case "getPastEntries": {
          return {
            ...state,
            isFetchingPastEntries: value,
          }
        }
        default:
          return { ...state };
      }
    }
    default:
      return { ...state };
  }
};

export default reducer;
