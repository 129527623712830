import {
  SET_SH_FETCHING_FLAG,
  GET_SH_OPERATION_LEADS,
  GET_SH_INCIDENT_DETAILS,
  SET_SH_SELECTED_SUPPORT_AREA,
  GET_SH_ONGOING_PM,
  GET_SH_INCIDENT_COMMENTS_HISTORY,
  GET_SH_INCIDENT_WORKLOG_HISTORY,
  GET_SH_ADMIN_USERS_LIST,
  SET_SH_TOAST_VISIBLE,
  GET_SH_AGENT_ROLES_LIST,
  GET_SH_CDS_USER_DATA,
  GET_SH_BANNER_MESSSAGE,
  GET_SH_SERVICES,
} from "./types";

const initialState = {
  selectedSupportArea: "",
  operationLeads: [],
  isFetchingOperationLeads: false,
  incidentDetailsData: {},
  isFetchingIncidentDetails: false,
  isFetchingOngoingPM: false,
  ongoingPM: [],
  commentHistory: [],
  worklogHistory: [],
  adminUsersList: [],
  toastVisible: false,
  toastText: "",
  agentRolesList: [],
  cdsUserData: {},
  isFetchingBanner: false,
  isFetchingServices: false,
  serversServices: [],
};

const reducer = (state = initialState, action) => {
  const type = action.type;

  switch (type) {
    case GET_SH_OPERATION_LEADS: {
      return {
        ...state,
        operationLeads: action.payload,
      };
    }
    case GET_SH_INCIDENT_DETAILS: {
      return {
        ...state,
        incidentDetailsData: action.payload,
      };
    }
    case SET_SH_SELECTED_SUPPORT_AREA: {
      return {
        ...state,
        selectedSupportArea: action.payload,
      };
    }
    case GET_SH_ONGOING_PM: {
      return {
        ...state,
        ongoingPM: action.payload,
      };
    }
    case GET_SH_INCIDENT_COMMENTS_HISTORY: {
      return {
        ...state,
        commentHistory: action.payload,
      };
    }
    case GET_SH_INCIDENT_WORKLOG_HISTORY: {
      return {
        ...state,
        worklogHistory: action.payload,
      };
    }
    case GET_SH_ADMIN_USERS_LIST: {
      return {
        ...state,
        adminUsersList: action.payload,
      };
    }
    case SET_SH_TOAST_VISIBLE: {
      return {
        ...state,
        toastVisible: action.payload?.visible,
        toastText: action.payload?.message,
      };
    }
    case GET_SH_AGENT_ROLES_LIST: {
      return {
        ...state,
        agentRolesList: action.payload,
      };
    }
    case GET_SH_CDS_USER_DATA: {
      return {
        ...state,
        cdsUserData: action.payload,
      };
    }
    case GET_SH_BANNER_MESSSAGE: {
      return {
        ...state,
        bannerMessage: action.payload,
      }
    }
    case GET_SH_SERVICES: {
      return {
        ...state,
        serversServices: action.payload,
      };
    }
    case SET_SH_FETCHING_FLAG: {
      const { apiType, value } = action.payload;
      switch (apiType) {
        case "getOperationLeadersDetails": {
          return {
            ...state,
            isFetchingOperationLeads: value,
          };
        }
        case "getIncidentDetails": {
          return {
            ...state,
            isFetchingIncidentDetails: value,
          };
        }
        case "opeMajorIncident": {
          return {
            ...state,
            isFetchingOpeMajorIncident: value,
          };
        }
        case "majorIncidentCandidate": {
          return {
            ...state,
            isFetchingMajorIncidentCandidate: value,
          };
        }
        case "openCriticalIncident": {
          return {
            ...state,
            isFetchingOpenCriticalIncident: value,
          };
        }
        case "openUrgentIncidents": {
          return {
            ...state,
            isFetchingOpenUrgentIncidents: value,
          };
        }
        case "getOngoingPowerMaintenance": {
          return {
            ...state,
            isFetchingOngoingPM: value,
          };
        }
        case "addIncidentToCSH": {
          return {
            ...state,
            isFetchingAddToCSH: value,
          };
        }
        case "getCommentHistory": {
          return {
            ...state,
            isFetchingCommentHistory: value,
          };
        }
        case "getWorklogHistory": {
          return {
            ...state,
            isFetchingWeblogHistory: value,
          };
        }
        case "getShiftHandoverAdminList": {
          return {
            ...state,
            isFetchingAdminList: value,
          };
        }
        case "getShiftHandoverAgentRolesList": {
          return {
            ...state,
            isFetchingAgentRoleList: value,
          };
        }
        case "getCDSUserData": {
          return {
            ...state,
            isFetchingCDSData: value,
          };
        }
        case "getBanner": {
          return {
            ...state,
            isFetchingBanner: value
          }
        }
        case "getServersServices": {
          return {
            ...state,
            isFetchingServices: value
          }
        }
        default:
          return { ...state };
      }
    }
    default:
      return { ...state };
  }
};

export default reducer;
