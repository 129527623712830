import {
  SET_FETCHING_FLAG,
  SET_SELECTED_SERVICE,
  GET_SERVICES_LIST,
  GET_REQUESTS_LIST,
  SET_SELECTED_REQUEST,
  CREATE_REQUEST,
  UPDATE_REQUEST,
  GET_SERVERS_LIST,
  SET_TOAST
} from "./types";

const initialState = {
  selectedService: "",
  servicesList: [],
  isFetchingServicesList: false,
  requestsList: [],
  isFetchingRequestsList: false,
  selectedRequest: {},
  serversList: [],
  isFetchingServersList: false,
  isToastVisible: false,
  toastText: ''
};

const reducer = (state = initialState, action) => {
  const type = action.type;

  switch (type) {
    case SET_SELECTED_SERVICE: {
      return {
        ...state,
        selectedService: action.payload,
      };
    }
    case GET_SERVICES_LIST: {
      return {
        ...state,
        servicesList: action.payload,
      };
    }
    case GET_REQUESTS_LIST: {
      return {
        ...state,
        requestsList: action.payload,
      };
    }
    case SET_SELECTED_REQUEST: {
      return {
        ...state,
        selectedRequest: action.payload,
      };
    }
    case CREATE_REQUEST: {
      return {
        ...state,
      };
    }
    case UPDATE_REQUEST: {
      return {
        ...state,
      };
    }
    case GET_SERVERS_LIST: {
      return {
        ...state,
        serversList: action.payload,
      };
    }
    case SET_FETCHING_FLAG: {
      let apiType = action.payload.apiType;
      let value = action.payload.value;
      switch (apiType) {
        case "getServicesList": {
          return {
            ...state,
            isFetchingServicesList: value,
          };
        }
        case "getRequestsList": {
          return {
            ...state,
            isFetchingRequestsList: value,
          };
        }
        case "getServersList": {
          return {
            ...state,
            isFetchingServersList: value,
          };
        }
        case "createRequest": {
          return {
            ...state,
            isCreatingRequest: value,
          };
        }
        default:
          return { ...state };
      }
    }
    case SET_TOAST: {
      return {
        ...state,
        isToastVisible: action.payload.display,
        toastText: action.payload.toastText,
      }
    }
    default:
      return { ...state };
  }
};

export default reducer;
