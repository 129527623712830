export const SET_GIT_TYPE = "SET_GIT_TYPE";
export const GET_SEARCH_RESULTS = "GET_SEARCH_RESULTS";
export const GET_REPO_OVERVIEW = "GET_REPO_OVERVIEW";
export const SET_FETCHING_FLAG = "SET_FETCHING_FLAG";
export const GET_ORG_DATA = "GET_ORG_DATA";
export const GET_COMMIT_FREQUENCY = "GET_COMMIT_FREQUENCY";
export const GET_TOP_CONTRIBUTORS = "GET_TOP_CONTRIBUTORS";
export const GET_BRANCH_OVERVIEW = "GET_BRANCH_OVERVIEW";
export const GET_PR_OVERVIEW = "GET_PR_OVERVIEW";
export const GET_ORG_ACTIVITIES = "GET_ORG_ACTIVITIES";
export const GET_ORG_ACTIVITIES_LIST = "GET_ORG_ACTIVITIES_LIST";
export const GET_ORG_TOP_CONTRIBUTORS = "GET_ORG_TOP_CONTRIBUTORS";
export const GET_DEPLOYMENT_STATS = "GET_DEPLOYMENT_STATS";
export const GET_DEPENDENCIES = "GET_DEPENDENCIES";
export const SET_TOKEN = "SET_TOKEN";
export const SET_DATA_CONTROL = 'SET_DATA_CONTROL';
export const TOGGLE_FETCH_DATA = 'TOGGLE_FETCH_DATA';