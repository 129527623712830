const initialState = {
  BuildID: "",
  Status: "PENDING",
  ConsoleInput: [],
  Texts: ["PENDING", "LOADING"],
  FunFacts: [],
  Token: "",
  ProjectID: "",
  InstanceName: "",
  ValidProjectID: false,
  ValidInstanceName: false,
  Network: "",
  Networks: [],
  Region: "",
  Regions: [],
  Zone: "",
  Zones: [],
  Machine: "",
  Machines: [],
  Storage: 0,
  ValidStorage: false,
  Image: "",
  ImageLink: "",
  ImageExtraPackages: "",
  EnableExtraImage: false,
  Images: [],
  Loading: false,
  Step: 0,
  MaxStep: 0,
  buildLog: "",
  showFullscreenLog: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "MLOPS_BUILD_ID": {
      return {
        ...state,
        BuildID: action.payload,
      };
    }
    case "MLOPS_SHOW_FULLSCREEN": {
      return {
        ...state,
        showFullscreenLog: action.payload,
      };
    }
    case "LOADING": {
      return {
        ...state,
        Loading: action.payload,
      };
    }
    case "STEP": {
      return {
        ...state,
        Step: action.payload,
      };
    }
    case "MAX_STEP": {
      return {
        ...state,
        MaxStep: action.payload,
      };
    }
    case "STATUS": {
      return {
        ...state,
        Status: action.payload,
      };
    }
    case "CONSOLE_INPUT": {
      return {
        ...state,
        ConsoleInput: [...state.ConsoleInput, action.payload],
      };
    }
    case "TEXTS": {
      return {
        ...state,
        Texts: action.payload,
      };
    }
    case "MLOPS_TOKEN": {
      return {
        ...state,
        Token: action.payload,
      };
    }
    case "MLOPS_PROJECT_ID": {
      return {
        ...state,
        ProjectID: action.payload,
      };
    }
    case "MLOPS_INSTANCE_NAME": {
      return {
        ...state,
        InstanceName: action.payload,
      };
    }
    case "MLOPS_VALID_PROJECT_ID": {
      return {
        ...state,
        ValidProjectID: action.payload,
      };
    }
    case "MLOPS_VALID_INSTANCE_NAME": {
      return {
        ...state,
        ValidInstanceName: action.payload,
      };
    }
    case "MLOPS_IMAGE": {
      return {
        ...state,
        Image: action.payload,
      };
    }
    case "MLOPS_IMAGE_LINK": {
      return {
        ...state,
        ImageLink: action.payload,
      };
    }
    case "MLOPS_IMAGE_EXTRA_PACKAGES": {
      return {
        ...state,
        ImageExtraPackages: action.payload,
      };
    }
    case "MLOPS_IMAGE_EXTRA_PACKAGES_ENABLE": {
      return {
        ...state,
        EnableExtraImage: action.payload,
      };
    }
    case "MLOPS_IMAGES": {
      return {
        ...state,
        Images: action.payload,
      };
    }
    case "MLOPS_NETWORKS": {
      return {
        ...state,
        Networks: action.payload,
      };
    }
    case "MLOPS_NETWORK": {
      return {
        ...state,
        Network: action.payload,
      };
    }
    case "MLOPS_REGIONS": {
      return {
        ...state,
        Regions: action.payload,
      };
    }
    case "MLOPS_REGION": {
      return {
        ...state,
        Region: action.payload,
      };
    }
    case "MLOPS_ZONES": {
      return {
        ...state,
        Zones: action.payload,
      };
    }
    case "MLOPS_ZONE": {
      return {
        ...state,
        Zone: action.payload,
      };
    }
    case "MLOPS_MACHINES": {
      return {
        ...state,
        Machines: action.payload,
      };
    }
    case "MLOPS_MACHINE": {
      return {
        ...state,
        Machine: action.payload,
      };
    }
    case "MLOPS_STORAGE": {
      return {
        ...state,
        Storage: action.payload,
      };
    }
    case "MLOPS_VALID_STORAGE": {
      return {
        ...state,
        ValidStorage: action.payload,
      };
    }
    case "LOG": {
      return {
        ...state,
        buildLog: action.payload,
      };
    }
    default:
      return state;
  }
};

export default reducer;
