const initialState = {

  serverCountByVersion: [],
  serverCountByEnv: [],

  serverListByEnv: [],
  serverListByOS: [],

  serviceList: [],
  hierarchicalData: {},
  swarmChartData: [],
  swarmChartRawData: {},
  serviceTabHostList: [],

  patchauditData: [],
  top20UnsupportedServices: [],
  serverList: [],

  coreBusinessList: [],
  solutionAreaList: [],
  
  selectedCoreBusiness: '',
  selectedSolutionArea: ''
};

const reducer = (state = initialState, action) => {
  const type = action.type;

  switch (type) {
    case "SET_WINDOWS_CORE_BUSINESS": {
      return {
        ...state,
        selectedCoreBusiness: action.payload
      }
    }
    case "SET_WINDOWS_CORE_SOLUTION_AREA": {
      return {
        ...state,
        selectedSolutionArea: action.payload
      }
    }
    case "SET_WINDOWS_CORE_BUSINESS_LIST": {
      return {
        ...state,
        coreBusinessList: action.payload
      }
    }
    case "SET_WINDOWS_CORE_SOLUTION_AREA_LIST": {
      return {
        ...state,
        solutionAreaList: action.payload
      }
    }
    case "SET_WINDOWS_SERVER_COUNT_BY_VERSION": {
        return {
            ...state,
            serverCountByVersion: action.payload.data,
            serverCount: action.payload.serverCount
        }
    }
    case "SET_WINDOWS_SERVER_COUNT_BY_ENV": {
        return {
            ...state,
            serverCountByEnv: action.payload.data,
            prodTotal: action.payload.prodTotal,
            total_servers: action.payload.total_servers,
        }
    }
    case "SET_WINDOWS_SERVICES_LIST": {
      return {
        ...state,
        serviceList: action.payload,
      };
    }

    case "SET_WINDOWS_HIERARCHICAL_DATA": {
      return {
        ...state,
        hierarchicalData: action.payload,
      };
    }

    case "SET_WINDOWS_SWARM_DATA": {
      return {
        ...state,
        swarmChartData: action.payload.formattedData,
        swarmChartRawData: action.payload.rawData,
      };
    }

    case "SET_WINDOWS_SERVICE_TAB_HOST_LIST": {
      return {
        ...state,
        serviceTabHostList: action.payload,
      };
    }

    case "SET_WINDOWS_PATCH_AUDIT_DATA": {
      return {
        ...state,
        patchauditData: action.payload,
      };
    }

    case "SET_WINDOWS_TOP_20_UNSUPPORTED_SERVICES": {
      return {
        ...state,
        top20UnsupportedServices: action.payload,
      };
    }
    case "SET_WINDOWS_SERVER_LIST": {
      return {
        ...state,
        serverList: action.payload,
      };
    }

    case 'SET_WINDOWS_FETCHING_FLAG': {
      let apiType = action.payload.apiType;
      let value = action.payload.value;
      switch (apiType) {
        case "fetchServerCountByVersion": {
          return {
            ...state,
            loadingServerCountByVersion: value,
          };
        }
        case "fetchServerCountByEnv": {
          return {
            ...state,
            loadingServerCountByEnv: value,
          };
        }
        case "fetchServicesList": {
          return {
            ...state,
            loadingServiceList: value,
          };
        }
        case "fetchhierarchicalview": {
          return {
            ...state,
            loadingHierarchicalData: value,
          };
        }
        case "fetchServersListBySupportSwarm": {
          return {
            ...state,
            loadingSwarmChartData: value,
          };
        }
        case "fetchPatchStatus": {
          return {
            ...state,
            loadingPatchauditData: value,
          };
        }
        case "fetchTop20Unsupported": {
          return {
            ...state,
            loadingTop20UnsupportedServices: value,
          };
        }
        case "fetchServerBasedOnfilter": {
          return {
            ...state,
            loadingServerList: value,
          };
        }
        default:
          return { ...state };
      }
    }

    default:
      return { ...state };
  }
};

export default reducer;
