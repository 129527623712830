import {
  SET_OS_TYPE,
  GET_SERVERS_LIST,
  SET_FETCHING_FLAG,
  EXISTING_TEMPLATES,
  ONBOARD_TEMPLATE,
  GET_CURRENT_STATUS,
  GET_RECENT_JOBS,
  GET_CURRENT_ACCESS,
} from "./types";

const initialState = {
  selectedOsType: "Linux",
  serversList: [],
  isFetchingServersList: false,
  existingTemplates: [],
  isFetchingExistingTemplates: false,
  recentJobs: [],
  allowedUsers: {}
};

const reducer = (state = initialState, action) => {
  const type = action.type;

  switch (type) {
    case SET_OS_TYPE: {
      return {
        ...state,
        selectedOsType: action.payload,
      };
    }
    case GET_SERVERS_LIST: {
      return {
        ...state,
        serversList: action.payload,
      };
    }
    case EXISTING_TEMPLATES: {
      return {
        ...state,
        existingTemplates: action.payload,
      };
    }
    case ONBOARD_TEMPLATE: {
      return {
        ...state,
      };
    }
    case GET_RECENT_JOBS: {
      return {
        ...state,
        recentJobs: action.payload,
      };
    }
    case GET_CURRENT_STATUS: {
      return {
        ...state,
      };
    }
    case GET_CURRENT_ACCESS: {
      return {
        ...state,
        allowedUsers: action.payload
      }
    }
    case SET_FETCHING_FLAG: {
      let apiType = action.payload.apiType;
      let value = action.payload.value;
      switch (apiType) {
        case "getServersList": {
          return {
            ...state,
            isFetchingServersList: value,
          };
        }
        case "getExistingTemplates": {
          return {
            ...state,
            isFetchingExistingTemplates: value,
          };
        }
        case "getRecentJobs": {
          return {
            ...state,
            isFetchingRecentJobs: value,
          };
        }
        default:
          return { ...state };
      }
    }
    default:
      return { ...state };
  }
};

export default reducer;
