import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";

import {
  // getCDSRole,
  getCDSToken,
  getCDSUserDetails,
} from "../../redux/authorization/actions";
import UnAuthorizedPage from "../../pages/Errors/UnAuthorizedPage";
import LoadingWidget from "../app/Loading/LoadingWidget";

const withCDSAuth = (Component) => (props) => {
  const { isAuthenticated, user, getAccessTokenSilently } = useAuth0();
  const [token, setToken] = useState("");

  useEffect(() => {
    async function getToken() {
        let auth0Token = await getAccessTokenSilently();
        setToken(auth0Token);
    }
    getToken();
}, [getAccessTokenSilently]);
  // const cdsRoles = useSelector((state) => state.at.cdsRoles);
  // const occPrivilege = useSelector((state) => state.at.occPrivilege);
  const loading = useSelector((state) => state.at.loading);

  const isOCCManager = useSelector((state) => state.at.isOCCManager);
  const isPCMember = useSelector((state) => state.at.isPCMember);
  const isUnionMember = useSelector((state) => state.at.isUnionMember);
  const isOCCAdmin = useSelector((state) => state.at.isOCCAdmin);
  const isGeneralEmployee = useSelector((state) => state.at.isGeneralEmployee);

  const dispatch = useDispatch();

  // const isOCCManager = sessionStorage.getItem('isOCCManager') && sessionStorage.getItem('isOCCManager') === 'true' ? true : false;
  // const isPCMember = sessionStorage.getItem('isPCMember') && sessionStorage.getItem('isPCMember') === 'true' ? true : false;
  // const isUnionMember = sessionStorage.getItem('isUnionMember') && sessionStorage.getItem('isUnionMember') === 'true' ? true : false;
  // const isOCCAdmin = sessionStorage.getItem('isOCCAdmin') && sessionStorage.getItem('isOCCAdmin') === 'true' ? true : false;

  // const validateToken = (token) => {
  //   sessionStorage.getItem("cdsToken_expires_in");
  // };

  useEffect(() => {
    try {
      async function fetchCDSData() {
        if (isAuthenticated && token !== "") {
          // dispatch(getCDSRole(user));
          if (true || !sessionStorage.getItem("cdsToken")) {
            await dispatch(getCDSToken(user, token));
          } else {
            await dispatch(
              getCDSUserDetails(user, sessionStorage.getItem("cdsToken"))
            );
          }
        }
      }
      fetchCDSData();
    } catch (error) {
      console.error(`error occured while getting CDS user details :: ${error}`);
    }
  }, [token]);

  if (loading) {
    return <LoadingWidget />;
  } else {
    if (
      isOCCAdmin ||
      isOCCManager ||
      isUnionMember ||
      isPCMember ||
      isGeneralEmployee
    ) {
      return <Component {...props} />;
    } else {
      return <UnAuthorizedPage />;
    }
  }
};
export default withCDSAuth;
