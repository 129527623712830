const initialState = {
  
  selectedPlatform: "OS",
  selectedType: "Linux",
  selectedService: "",
  servicesList: [],
  isFetchingServicesList: false,
  serversList: [],
  isFetchingServersList: false,
  eventsList: [],
  isFetchingEventsList : false,
  toastVisible: false,
  toastText: "",
};

const reducer = (state = initialState, action) => {
  const type = action.type;
  switch (type) {
    case "USV_SET_PLATFORM" : {
      return {
        ...state,
        selectedPlatform: action.payload,
      }
    }
    case "USV_SET_TYPE" : {
      return {
        ...state,
        selectedType: action.payload,
      }
    }
    case "USV_GET_SERVICES_LIST": {
      return {
        ...state,
        servicesList: action.payload,
      };
    }
    case "USV_SET_SERVICE" : {
      return {
        ...state,
        selectedService: action.payload,
      }
    }
    case "USV_GET_SERVERS_LIST": {
      return {
        ...state,
        serversList: action.payload,
      }
    }
    case "USV_GET_EVENTS_LIST": {
      return {
        ...state,
        eventsList: action.payload
      }
    }
    case "USV_SET_TOAST_VISIBLE": {
      return {
        ...state,
        toastVisible: action.payload?.visible,
        toastText: action.payload?.message,
      };
    }
    case "USV_SET_FETCHING_FLAG": {
      let apiType = action.payload.apiType;
      let value = action.payload.value;
      switch (apiType) {
        case "getServicesList": {
          return {
            ...state,
            isFetchingServicesList: value,
          };
        }
        case "getServersList": {
          return {
            ...state,
            isFetchingServersList: value,
          };
        }
        case "getEvents": {
          return {
            ...state,
            isFetchingEventsList: value,
          };
        }
        default:
          return { ...state };
      }
    }
    default:
      return { ...state };
  }
};

export default reducer;
