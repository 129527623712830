export const AUTHORIZATION_REQUEST = 'AUTHORIZATION_REQUEST';
export const AUTHORIZATION_SUCCESS = 'AUTHORIZATION_SUCCESS';
export const AUTHORIZATION_FAILED = 'AUTHORIZATION_FAILED';
export const SAVE_CDS_ROLE = 'SAVE_CDS_ROLE';
export const SET_LOADING = 'SET_LOADING';
export const LOGOUT = 'LOGOUT';
export const SET_CDS_USER_ID = 'SET_CDS_USER_ID';
export const SAVE_SPOOF_CDS_ROLE = 'SAVE_SPOOF_CDS_ROLE';
export const TOGGLE_CDS_SPOOFING = 'TOGGLE_CDS_SPOOFING';
export const GET_AUTH0_TOKEN = 'GET_AUTH0_TOKEN';
export const GET_APIM_TOKEN = 'GET_APIM_TOKEN';
export const SET_USER_EMAIL = 'SET_USER_EMAIL';
export const SET_USER_GROUPS = 'SET_USER_GROUPS';